import React from 'react';

import Status from '@zero5/ui/lib/Status';

import { PaymentStatus as PaymentStatusType } from '@/api/paymentHistory/models';

interface Props {
  status: PaymentStatusType;
}

const mapPaymentStatusToStatus: Record<Exclude<PaymentStatusType, 'FAILED'>, {
  color: string;
  type: 'regular';
  label: string;
}> & {
  FAILED: {
    type: 'error';
    label: string;
  }
} = {
  FAILED: {
    type: 'error',
    label: 'Failed',
  },
  SUCCEEDED: {
    color: '#386344',
    type: 'regular',
    label: 'Succeeded',
  },
  PENDING: {
    color: '#2541B2',
    type: 'regular',
    label: 'Pending',
  },
};

const PaymentStatus: React.FC<Props> = ({ status }) => {
  return (
    <Status
      {...mapPaymentStatusToStatus[status]}
    />
  );
};

export default PaymentStatus;