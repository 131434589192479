import React from 'react';
import styled from 'styled-components';
import { Breadcrumbs } from '@material-ui/core';
import flow from 'lodash/flow';

import { Button, Table, Link, Card, CardHead, RowsMap, InfoButton, TableContent, Modal } from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import vars from '@zero5/ui/lib/styles/vars';

import { PaymentHistory } from '@/api/paymentHistory/models';
import usePaymentHistoriesQuery from '@/api/paymentHistory/usePaymentHistoriesQuery';
import usePaymentReceiptCsvMutation from '@/api/paymentHistory/usePaymentReceiptCsvMutation';

import Page from '@/components/common/Page';
import PaymentStatus from '@/components/paymentHistory/PaymentStatus';
import PaymentHistoryModal from '@/components/paymentHistory/PaymentHistoryModal';
import { PageHeading } from '@/components/typography';

import useDateModule from '@/utils/date/useDateModule';
import useModal from '@/utils/hooks/useModal';
import { withLoading } from '@/utils/hocs/withLoading';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPaymentMethodMessage, withPreloadPaymentMethodMessage } from '@/utils/hocs/withPaymentMethodMessage';

const InvoiceHistory: React.FC = () => {
  const {
    formatDateMonthYear,
  } = useDateModule();

  const [
    selectedPaymentHistories,
    setSelectedPaymentHistories,
  ] = React.useState<Array<PaymentHistory>>([]);

  const paymentHistoriesQuery = usePaymentHistoriesQuery({});

  const paymentReceiptCsvMutation = usePaymentReceiptCsvMutation();

  const [
    PaymentHistoryInfoModal,
    openPaymentHistoryInfoModal,
    closePaymentHistoryInfoModal,
  ] = useModal<string>('id', StyledPaymentHistoryInfoModal);

  const exportReceiptsHandler = React.useCallback(async () => {
    const link = await paymentReceiptCsvMutation.mutateAsync({
      transactionIds: selectedPaymentHistories.map(({ receiptNumber }) => receiptNumber),
    });

    window.open(link, '_blank');
  }, [paymentReceiptCsvMutation, selectedPaymentHistories]);

  const rowsMap: RowsMap<PaymentHistory> = [
    {
      title: 'Date',
      id: 'date',
      data: ({ date }) => formatDateMonthYear(date),
    },
    {
      title: 'Garage Name',
      id: 'garageName',
      data: ({ garageName }) => garageName,
    },
    {
      title: 'Item',
      id: 'item',
      data: ({ item }) => item,
    },
    {
      title: 'Payment Status',
      id: 'paymentStatus',
      data: ({ paymentStatus }) => <PaymentStatus status={paymentStatus} />,
    },
    {
      title: 'Receipt No.',
      id: 'receiptNumber',
      data: ({ receiptNumber }) => receiptNumber,
    },
    {
      title: 'Amount',
      id: 'amount',
      data: ({ amount }) => formatPrice(amount),
    },
    {
      title: 'Info',
      id: 'info',
      data: (item) => (
        <InfoButton onClick={() => openPaymentHistoryInfoModal(item.receiptNumber)} />
      ),
      CellProps: {
        padding: 'none',
      },
      disableSorting: true,
    },
  ];

  return (
    <Page>
      <StyledBreadcrumbs>
        <Link to="/user/account">Home</Link>
        <Link to="/user/payment-history">Payment History</Link>
      </StyledBreadcrumbs>
      <PageHeading>Payment History</PageHeading>
      {Boolean(paymentHistoriesQuery.data?.failedTransactions.length) && (
        <FailedPaymentsWarning>
          You have one failed payment. Please update the payment method.
          If the payment is not processed within 5 days, your
          {' '}
          {paymentHistoriesQuery.data!.failedTransactions[0].item}
          {' '}
          will be canceled.
        </FailedPaymentsWarning>
      )}
      <Card>
        <CardHead
          title="Payment History"
          headerContent={(
            <Button
              disabled={!selectedPaymentHistories.length}
              loading={paymentReceiptCsvMutation.isLoading}
              onClick={exportReceiptsHandler}
            >
              Export
            </Button>
          )}
          align="flex-start"
        />
        <TableContent
          isLoading={paymentHistoriesQuery.isLoading}
          data={paymentHistoriesQuery.data?.paymentHistories}
        >
          <Table
            controls="checkbox"
            selected={selectedPaymentHistories}
            onItemSelect={
              (selectedItems) => setSelectedPaymentHistories(
                Array.isArray(selectedItems) ? selectedItems : [selectedItems],
              )
            }
            getSelectComparator={(item) => item?.receiptNumber}
            data={paymentHistoriesQuery.data?.paymentHistories!}
            rowsMap={rowsMap}
            minWidth="800px"
          />
        </TableContent>
      </Card>
      <PaymentHistoryInfoModal
        fullScreenSize="540px"
      >
        {({ data }) => (
          <PaymentHistoryModal
            onClose={closePaymentHistoryInfoModal}
            id={data}
          />
        )}
      </PaymentHistoryInfoModal>
    </Page>
  );
};

const FailedPaymentsWarning = styled.p`
  margin: 10px 0;
  margin-top: 0;

  color: ${vars.palette.danger};
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 10px;
`;

const StyledPaymentHistoryInfoModal = styled(Modal)`
  max-width: 540px;
`;

export default flow(
  withPaymentMethodMessage,
  withPreloadPaymentMethodMessage,
  withAuth(),
  withLoading(),
)(InvoiceHistory);
