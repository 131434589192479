import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';

import { ConfirmationModalContent, Layout } from '@zero5/ui';

import user from '@/api/user';

import useModal from '@/utils/hooks/useModal';

import AccountIcon from '@/assets/icons/account.inline.svg';
import CardIcon from '@/assets/icons/card.inline.svg';
import FactCheckIcon from '@/assets/icons/fact_check.inline.svg';
import HistoryBackIcon from '@/assets/icons/history_back.inline.svg';
import SignOutIcon from '@/assets/icons/sign_out.inline.svg';
import Zero5Logo from '@/assets/images/zero5_logo.png';

import UserInfo from '../UserInfo';

interface Props {
  children: React.ReactNode;
}

const Page: React.FC<Props> = ({ children }) => {
  const history = useHistory();

  const [
    SignOutModal,
    openSignOutModal,
    closeSignOutModal,
  ] = useModal();

  const navigationLinks: Array<{
    title: string;
    Icon: React.FC<React.SVGAttributes<SVGElement>>;
    path: string;
    onClick?: never;
  } | {
    title: string;
    Icon: React.FC;
    path?: never;
    onClick: () => void;
  }> = React.useMemo(() => [
    {
      title: 'My Account',
      Icon: AccountIcon,
      path: '/user/account',
    },
    {
      title: 'Payment Methods',
      Icon: CardIcon,
      path: '/user/payment',
    },
    {
      title: 'Permits',
      Icon: FactCheckIcon,
      path: '/user/permits',
    },
    {
      title: 'Payment History',
      Icon: HistoryBackIcon,
      path: '/user/payment-history',
    },
    {
      title: 'Sign Out',
      Icon: SignOutIcon,
      onClick: openSignOutModal,
    },
  ], [openSignOutModal]);

  const signOutHandler = React.useCallback(async () => {
    user.signOut();
    history.push('/login');
  }, [history]);

  return (
    <>
      <Layout
        navigationLinks={navigationLinks}
        sidebarTopContent={
          <UserInfo />
        }
        logoItem={<LogoImage src={Zero5Logo} alt="zero5" width="70px" />}
      >
        {children}
      </Layout>
      <SignOutModal title="Want to Leave?">
        <ConfirmationModalContent
          onAccept={signOutHandler}
          onReject={closeSignOutModal}
          text="Please confirm. You will be redirected to the login page."
        />
      </SignOutModal>
    </>
  );
};

const LogoImage = styled.img`
  display: block;
`;

export default Page;
