import React, { Suspense } from 'react';
import styled from 'styled-components';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { StripeElementChangeEvent } from '@stripe/stripe-js';

import {
  AutocompleteInput,
  Button,
  Checkbox,
  Column,
  Columns,
  Grid,
  GridItem,
  Input,
  ModalTitle,
  Row,
  Status,
} from '@zero5/ui';
import toast from '@zero5/ui/lib/utils/toast';
import { capitalizeFirsLetterEveryWord } from '@zero5/ui/lib/utils/formatters/capitalize';
import { AutocompleteInputProps, placeVariants } from '@zero5/ui/lib/AutocompleteInput';

import useAttachPaymentMethodMutation from '@/api/paymentMethod/useAttachPaymentMethodMutation';
import usePaymentMethodsQuery from '@/api/paymentMethod/usePaymentMethodsQuery';
import useUpdatePaymentMethodMutation from '@/api/paymentMethod/useUpdatePaymentMethodMutation';
import useSetDefaultPaymentMutation from '@/api/paymentMethod/useSetDefaultPaymentMutation';

import ModalTitleButton from '@/components/common/ModalTitleButton';

import { isDashValid } from '@/utils/validation';
import { dateMask } from '@/utils/input';
import { formatCard } from '@/utils/formatters/payment';

import CardsImage from '@/assets/images/cards.svg';

import PaymentInput from './PaymentInput';

const emptyValues = {
  cardholderName: '',
  address: '',
  city: '',
  state: '',
  postalCode: '',
  isDefault: false,
};

const expDateSeparator = '/';

const validationSchema = yup.object({
  cardholderName: yup
    .string()
    .trim()
    .label('Cardholder Name')
    .required()
    .test((value) => Boolean(value) && isDashValid(value!)),
  address: yup.string().label('Street Address').required(),
  city: yup.string().label('City').required(),
  state: yup.string().label('State').required(),
  postalCode: yup.string()
    .matches(/^[0-9]+$/)
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .label('Postal Code')
    .required(),
  isDefault: yup.boolean(),
});

type DataProps = {
  mode: 'add';
  id?: never;
} | {
  mode: 'info';
  id: number;
} | {
  mode: 'edit';
  id: number;
};

type CommonProps = {
  paymentMethodsCount: number;
  onCancel: () => void;
};

type Props = DataProps & CommonProps;

type Formik = {
  cardholderName: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  isDefault: boolean;
  expDate?: string;
};

const PaymentMethodLoader: React.FC<Props> = ({ ...props }) => {
  return (
    <Suspense fallback={<>loading</>}>
      <PaymentMethodModal {...props} />
    </Suspense>
  );
};

const PaymentMethodModal: React.FC<Props> = (props) => {
  const {
    id,
    mode: modeProps,
    paymentMethodsCount,
    onCancel,
  } = props;

  const [mode, setMode] = React.useState(modeProps);

  const [paymentErrors, setPaymentErrors] = React.useState<{
    cardNumber?: string;
    cardCvv?: string;
    cardExpiry?: string;
  }>({
    cardNumber: undefined,
    cardCvv: undefined,
    cardExpiry: undefined,
  });

  const data = usePaymentMethodsQuery({
    suspense: true,
    enabled: modeProps !== 'add',
  }).data?.find((pm) => pm.id === id);

  React.useEffect(() => {
    if (!data && modeProps !== 'add') {
      onCancel();
      toast('error', 'Payment method is not found');
    }
  }, [data, modeProps, onCancel]);

  const attachPaymentMutation = useAttachPaymentMethodMutation();
  const updatePaymentMutation = useUpdatePaymentMethodMutation();
  const setDefaultPaymentMutation = useSetDefaultPaymentMutation();

  const formik = useFormik<Formik>({
    initialValues: data ? {
      cardholderName: data.name,
      address: data.address.line1,
      city: data.address.city,
      state: data.address.state,
      postalCode: data.address.postal_code,
      isDefault: data.isDefault,
      expDate: `${
        data.card.exp_month < 10 ? '0' : ''
      }${
        data.card.exp_month
      }${
        expDateSeparator
      }${
        data.card.exp_year % 100
      }`,
    } : emptyValues,
    enableReinitialize: true,
    validationSchema,
    onSubmit: async (values) => {
      if (mode === 'add') {
        await attachPaymentMutation.mutateAsync(values);
      }

      if (mode === 'edit') {
        const requests = [];

        if (values.isDefault) {
          const setDefaultPMRequest = setDefaultPaymentMutation.mutateAsync({
            paymentMethodId: data!.id,
          });
          requests.push(setDefaultPMRequest);
        }

        const updatePMRequest = updatePaymentMutation.mutateAsync({
          paymentMethodId: data!.id,
          payload: {
            card: {
              exp_month: parseInt(values.expDate!.split(expDateSeparator)[0], 10),
              exp_year: parseInt(`20${values.expDate!.split(expDateSeparator)[1]}`, 10),
            },
            address: {
              city: values.city,
              line1: values.address,
              postal_code: values.postalCode,
              state: values.state,
            },
            cardHolderName: values.cardholderName,
          },
        });
        requests.push(updatePMRequest);

        await Promise.all(requests);
      }

      onCancel();
    },
  });

  const cardholderNameChangeHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(
      e.target.name,
      capitalizeFirsLetterEveryWord(e.target.value),
    );
  }, [formik]);

  const addressPredictionSelectHandler = React.useCallback<NonNullable<AutocompleteInputProps['onSelectPrediction']>>(
    (prediction) => {
      if (!prediction) return;

      const addressComponents = prediction.address_components;

      const state = addressComponents?.find(
        (component) => component.types.includes(placeVariants.state.filter),
      )?.long_name;
      const city = addressComponents?.find(
        (component) => component.types.includes(placeVariants.city.filter),
      )?.long_name;
      const postalCode = addressComponents?.find(
        (component) => component.types.includes(placeVariants.postalCode.filter),
      )?.long_name;

      if (postalCode) formik.setFieldValue('postalCode', postalCode);
      if (state) formik.setFieldValue('state', state);
      if (city) formik.setFieldValue('city', city);
    },
    [formik],
  );

  return (
    <Columns columnFrom="1160px">
      <LeftColumn>
        <Row gap="20px">
          <TitleWrapper>
            {mode === 'add' && <ModalTitle>Add a New Card</ModalTitle>}
            {mode === 'info' && (
              <ModalTitleButton
                onClick={() => setMode('edit')}
              >
                Card Information
              </ModalTitleButton>
            )}
            {mode === 'edit' && <ModalTitle>Edit Card Information</ModalTitle>}
          </TitleWrapper>
          {data?.isDefault && (
            <Status label="Default card" type="regular" color="#49835A" />
          )}
        </Row>
        <CardsImageComponent src={CardsImage} alt="add card" width="280" />
      </LeftColumn>
      <Column>
        <Form onSubmit={formik.handleSubmit}>
          <Grid>
            <StyledTypography>Credit Card Information</StyledTypography>
            <StyledGridItem columns={6}>
              <Input
                label="Cardholder Name"
                name="cardholderName"
                onChange={cardholderNameChangeHandler}
                value={formik.values.cardholderName}
                error={formik.touched.cardholderName && Boolean(formik.errors.cardholderName)}
                helperText={formik.touched.cardholderName ? formik.errors.cardholderName : undefined}
                disabled={mode === 'info' || mode === 'edit'}
              />
            </StyledGridItem>
            <StyledGridItem columns={6}>
              <PaymentInput
                component={CardNumberElement}
                onChange={(e: StripeElementChangeEvent) => {
                  setPaymentErrors((prev) => ({
                    ...prev,
                    cardNumber: e.error?.message,
                  }));
                }}
                label="Card Number"
                value={data && formatCard(data.card)}
                error={Boolean(paymentErrors.cardNumber)}
                helperText={paymentErrors.cardNumber}
                disabled={mode === 'info' || mode === 'edit'}
              />
            </StyledGridItem>
            <StyledGridItem columns={6}>
              <PaymentInput
                component={CardCvcElement}
                onChange={(e: StripeElementChangeEvent) => {
                  setPaymentErrors((prev) => ({
                    ...prev,
                    cardCvv: e.error?.message,
                  }));
                }}
                label="CVV"
                value={data && '***'}
                error={Boolean(paymentErrors.cardCvv)}
                helperText={paymentErrors.cardCvv}
                options={{
                  placeholder: 'CVV',
                }}
                disabled={mode === 'info' || mode === 'edit'}
              />
            </StyledGridItem>
            <StyledGridItem columns={6}>
              {mode === 'edit' ? (
                <Input
                  label="Expiration Date"
                  name="address"
                  onChange={(e) => {
                    const maskedValue = dateMask(e.target.value, expDateSeparator);
      
                    if (typeof maskedValue !== 'undefined') {
                      formik.setFieldValue('expDate', maskedValue);
                    }
                  }}
                  value={formik.values.expDate}
                />
              ) : (
                <PaymentInput
                  component={CardExpiryElement}
                  onChange={(e: StripeElementChangeEvent) => {
                    setPaymentErrors((prev) => ({
                      ...prev,
                      cardExpiry: e.error?.message,
                    }));
                  }}
                  label="Expiration Date"
                  value={data && `${data.card.exp_month}/${data.card.exp_year % 100}`}
                  error={Boolean(paymentErrors.cardExpiry)}
                  helperText={paymentErrors.cardExpiry}
                  disabled={mode === 'info'}
                />
              )}
            </StyledGridItem>
            <StyledTypography>Billing Address</StyledTypography>
            <StyledGridItem columns={12}>
              <AutocompleteInput
                required
                label="Address"
                onChangeValue={(value) => formik.setFieldValue('address', value)}
                optionVariant="full"
                placeVariant="address"
                onSelectPrediction={addressPredictionSelectHandler}
                value={formik.values.address}
                error={formik.touched.address && Boolean(formik.errors.address)}
                helperText={formik.touched.address ? formik.errors.address : undefined}
                disabled={mode === 'info'}
              />
            </StyledGridItem>
            <StyledGridItem columns={4}>
              <AutocompleteInput
                label="City"
                onChangeValue={(value) => formik.setFieldValue('city', value)}
                optionVariant="main"
                placeVariant="city"
                value={formik.values.city}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city ? formik.errors.city : undefined}
                disabled={mode === 'info'}
              />
            </StyledGridItem>
            <StyledGridItem columns={4}>
              <AutocompleteInput
                label="State"
                onChangeValue={(value) => formik.setFieldValue('state', value)}
                optionVariant="main"
                placeVariant="state"
                value={formik.values.state}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state ? formik.errors.state : undefined}
                disabled={mode === 'info'}
              />
            </StyledGridItem>
            <StyledGridItem columns={4}>
              <AutocompleteInput
                label="Postal Code"
                onChangeValue={(value) => {
                  if (value.match(/^[0-9]*$/)) {
                    formik.setFieldValue('postalCode', value);
                  }
                }}
                optionVariant="main"
                placeVariant="postalCode"
                value={formik.values.postalCode}
                error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                helperText={formik.touched.postalCode ? formik.errors.postalCode : undefined}
                disabled={mode === 'info'}
              />
            </StyledGridItem>
          </Grid>
          {/* hide if info; show if add modal and some payment methods exist; show if edit */}
          {(mode !== 'info' && ((mode === 'add' && paymentMethodsCount > 0) || (mode === 'edit'))) && (
            <StyledCheckbox
              checked={formik.values.isDefault}
              disabled={formik.initialValues.isDefault}
              onChange={(ignoredEvent, isChecked) => formik.setFieldValue('isDefault', isChecked)}
              label="Set this card as a default payment method"
            />
          )}
          <ButtonsRow justifyContent="flex-end" gap="20px">
            <Button
              variant={mode === 'info' ? 'contained' : 'outlined'}
              type="button"
              onClick={onCancel}
              disabled={formik.isSubmitting}
            >
              {mode === 'info' ? 'Close' : 'Cancel'}
            </Button>
            {mode !== 'info' && (
              <Button
                type="submit"
                loading={formik.isSubmitting}
                disabled={!formik.dirty}
              >
                {mode === 'add' ? 'Add Card' : 'Save'}
              </Button>
            )}
          </ButtonsRow>
        </Form>
      </Column>
    </Columns>
  );
};

const LeftColumn = styled(Column)`
  display: grid;
  grid-template-rows: min-content 1fr;
  align-items: center;
`;

const TitleWrapper = styled.div`
  width: 185px;
`;

const CardsImageComponent = styled.img`
  margin: auto;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;

  width: 700px;
  height: 100%;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledTypography = styled.h5`
  margin: 0;

  grid-column: 1 / 12;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
`;

const StyledGridItem = styled(GridItem)`
  @media (max-width: 660px) {
    grid-column: span 12;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  margin-top: 20px;
`;

const ButtonsRow = styled(Row)`
  padding-top: 40px;
  margin-top: auto;
`;

export default PaymentMethodLoader;
