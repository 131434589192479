import { Breadcrumbs } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import { Card, CardHead, Link } from '@zero5/ui';

import { PageHeading } from '@/components/typography';
import Page from '@/components/common/Page';
import AccountSettings from '@/components/account/AccountSettings';
import Notifications from '@/components/account/Notifications';

const Account: React.FC = ({}) => {
  return (
    <Page>
      <StyledBreadcrumbs>
        <Link to="/user/account">Home</Link>
        <Link to="/user/account">My Account</Link>
      </StyledBreadcrumbs>
      <PageHeading>Payment Methods</PageHeading>
      <Grid>
        <Card>
          <CardHead title="Account Settings" />
          <ContentWrapper>
            <AccountSettings />
          </ContentWrapper>
        </Card>
        <Card>
          <CardHead title="Notification Log" />
          <Notifications />
        </Card>
      </Grid>
    </Page>
  );
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 880px) {
    grid-template-columns: 100%;
  }
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 10px;
`;

const ContentWrapper = styled.div`
  overflow: auto;
`;

export default Account;
