import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { attachDriverPaymentMethod } from '@zero5/payment-api';

import { handleError } from '@/utils/handleError';

import { paymentMethodsQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  cardholderName: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  isDefault: boolean;
};

const useAttachPaymentMethodMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();
  const stripe = useStripe();
  const elements = useElements();

  return useMutation(
    'attachPaymentMethod',
    async (params) => {
      if (!stripe || !elements) {
        throw new Error('Stripe has not been initialized.');
      }
  
      const cardElement = elements.getElement(CardNumberElement);
  
      const paymentMethod = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement!,
        billing_details: {
          name: params.cardholderName,
          address: {
            line1: params.address,
            city: params.city,
            state: params.state,
            postal_code: params.postalCode,
          },
        },
      });
  
      if (paymentMethod.error) {
        throw new Error(paymentMethod.error.message);
      }
  
      return attachDriverPaymentMethod({
        paymentMethodId: paymentMethod.paymentMethod.id,
        isDefault: params.isDefault,
      });
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(paymentMethodsQueryKeys.list());

        options?.onSuccess?.(...params);
      },
      onError: (error) => {
        handleError(error, 'Error while creating payment method.');
      },
    },
  );
};

export default useAttachPaymentMethodMutation;
