import { useQuery, UseQueryOptions } from 'react-query';

import { getDriverPostReceipt } from '@zero5/payment-api';

import { paymentHistoryQueryKeys } from './queryKeys';
import { mapPaymentReceipt } from './mappings';

type Response = ReturnType<typeof mapPaymentReceipt>;
type Params = {
  transactionId: string;
};
type Key = ReturnType<typeof paymentHistoryQueryKeys.receipt>;

const usePaymentReceiptQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    paymentHistoryQueryKeys.receipt(params.transactionId),
    async () => {
      const response = await getDriverPostReceipt(params.transactionId);

      return mapPaymentReceipt(response.postReceipt);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePaymentReceiptQuery;
