import React from 'react';
import styled from 'styled-components';
import {
  Breadcrumbs,
} from '@material-ui/core';
import flow from 'lodash/flow';

import {
  Table,
  RowsMap,
  Link,
  TableContent,
  Card,
  InfoButton,
  DeleteButton,
  ConfirmationModalContent,
  CardHead,
} from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import usePermitEntriesQuery from '@/api/permit/usePermitEntriesQuery';
import { PermitEntry } from '@/api/permit/models';
import usePaymentMethodsQuery from '@/api/paymentMethod/usePaymentMethodsQuery';
import useDeletePermitMutation from '@/api/permit/useDeletePermitMutation';

import Page from '@/components/common/Page';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import NoPaymentMethods from '@/components/payments/NoPaymentMethods';
import PermitModal from '@/components/permit/PermitModal';
import { PageHeading } from '@/components/typography';
import PermitInviteStatus from '@/components/permit/PermitInviteStatus';

import useModal from '@/utils/hooks/useModal';
import { withLoading } from '@/utils/hocs/withLoading';
import { withAuth } from '@/utils/hocs/withAuth';
import { withPaymentMethodMessage, withPreloadPaymentMethodMessage } from '@/utils/hocs/withPaymentMethodMessage';
import useDateModule from '@/utils/date/useDateModule';
import { emptyFieldStub } from '@/utils/emptyFieldStub';

export const subscriptionStatusToLabel: Record<
string,
{
  label: string;
  color: string;
}
> = {
  ACTIVE: {
    label: 'Active',
    color: vars.palette.primary,
  },
  INACTIVE: {
    label: 'Inactive',
    color: '#dc3545',
  },
  PAYMENT_REQUESTED: {
    label: 'Payment Requested',
    color: '#e2df28',
  },
  PAYMENT_PROCESSING: {
    label: 'Payment Processing',
    color: '#e2df28',
  },
};

const PermitsContent: React.FC = () => {
  const {
    formatFullMonthDateYear,
  } = useDateModule();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const [
    AddPermitModal,
    openAddPermitModal,
    closeAddPermitModal,
  ] = useModal<number>('permitInviteId');

  const [
    DeletePermitModal,
    openDeletePermitModal,
    closeDeletePermitModal,
  ] = useModal<PermitEntry>();

  const [
    InfoPermitModal,
    openInfoPermitModal,
    closeInfoPermitModal,
  ] = useModal<number>('permit');

  const permitsQuery = usePermitEntriesQuery({
    take: rowsPerPage,
    skip: page * rowsPerPage,
  });
  const paymentMethodsQuery = usePaymentMethodsQuery();

  const deletePermitMutation = useDeletePermitMutation({
    onSuccess: () => {
      closeDeletePermitModal();
    },
  });

  const permitsTableRowsMap: RowsMap<PermitEntry> = React.useMemo(
    () => [
      {
        title: 'Garage Name',
        id: 'garageId',
        data: (item) => item.permitInvite.permitType.garageId,
      },
      {
        title: 'License Plate',
        id: 'lpNum',
        data: (item) => item.vehicle ? item.vehicle.lpNum : emptyFieldStub,
      },
      {
        title: 'Valid Date',
        id: 'startTime',
        data: (item) => {
          if (!item.permit?.startTime || !item.permit?.endTime) return emptyFieldStub;

          return `${
            formatFullMonthDateYear(new Date(item.permit.startTime))
          } - ${
            formatFullMonthDateYear(new Date(item.permit.endTime))
          }`;
        },
      },
      {
        title: 'Permit Status',
        id: 'status',
        data: (item) => <PermitInviteStatus status={item.permitInvite.status} />,
      },
      {
        title: 'Permit price',
        id: 'cost',
        data: (item) => formatPrice(item.permitInvite.permitType.price),
      },
      {
        title: 'Info',
        id: 'info',
        disableSorting: true,
        data: (item) => item.permit ? (
          <InfoButton
            onClick={() => openInfoPermitModal(item.permitInvite.permitInviteId)}
          />
        ) : (
          <InfoButton
            onClick={() => openAddPermitModal(item.permitInvite.permitInviteId)}
          />
        ),
      },
      {
        title: 'Delete',
        id: 'delete',
        disableSorting: true,
        data: (item) => (
          <DeleteButton
            onClick={() => openDeletePermitModal(item)}
          />
        ),
      },
    ],
    [formatFullMonthDateYear, openInfoPermitModal, openAddPermitModal, openDeletePermitModal],
  );

  if (permitsQuery.isLoading || paymentMethodsQuery.isLoading) {
    return <LoadingSpinner />;
  }

  if (!paymentMethodsQuery.data?.length) {
    return <NoPaymentMethods />;
  }

  return (
    <>
      <Card>
        <CardHead
          title="Permit List"
        />
        <TableContent
          isLoading={false}
          data={permitsQuery.data?.entries}
        >
          <StyledTable
            rowsMap={permitsTableRowsMap}
            data={permitsQuery.data?.entries!}
            size="small"
            minWidth="900px"
            onPageChange={(e, newPage) => setPage(newPage)}
            onRowsPerPageChange={(e) => setRowsPerPage(Number(e.target.value))}
          />
        </TableContent>
      </Card>

      <AddPermitModal
        fullScreenSize="660px"
      >
        {({ data }) => (
          <PermitModal mode="add" id={data} onCancel={closeAddPermitModal} />
        )}
      </AddPermitModal>
      <InfoPermitModal
        fullScreenSize="660px"
      >
        {({ data }) => (
          <PermitModal mode="info" id={data} onCancel={closeInfoPermitModal} />
        )}
      </InfoPermitModal>
      <DeletePermitModal>
        {({ data }) => (
          <ConfirmationModalContent
            onAccept={() => deletePermitMutation.mutate({ permitInviteId: data.permitInvite.permitInviteId })}
            onReject={closeDeletePermitModal}
            loading={deletePermitMutation.isLoading}
            text={
              `Are you sure you want to delete this permit (${
                data.permitInvite.permitType.garageId
              }, ${
                data.permitInvite.permitType.name
              })?`
            }
            acceptText="Delete"
          />
        )}
      </DeletePermitModal>
    </>
  );
};

const Permits: React.FC = () => {
  return (
    <Page>
      <StyledBreadcrumbs>
        <Link to="/user/account">Home</Link>
        <Link to="/user/permits">Permits</Link>
      </StyledBreadcrumbs>
      <PageHeading>
        Permits
      </PageHeading>
      <PermitsContent />
    </Page>
  );
};

const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 10px;
`;

const StyledTable = styled(Table)`
  margin-bottom: 20px;
` as typeof Table;

export default flow(
  withPaymentMethodMessage,
  withPreloadPaymentMethodMessage,
  withAuth(),
  withLoading(),
)(Permits);
