import { useQuery, UseQueryOptions } from 'react-query';

import { endUserGetDriverPermitTable } from '@zero5/permit-api';

import { permitQueryKeys } from './queryKeys';
import { mapPermitEntries } from './mappings';

type Response = ReturnType<typeof mapPermitEntries>;
type Params = {
  skip?: number;
  take?: number;
};
type Key = ReturnType<typeof permitQueryKeys.list>;

const usePermitEntriesQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    permitQueryKeys.list(params),
    async () => {
      const response = await endUserGetDriverPermitTable(params);

      return mapPermitEntries(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePermitEntriesQuery;
