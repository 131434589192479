import { AuthEngine } from '@zero5/ui-utils';

import authApiProvider from '@/api/user/AuthApiProvider';

import history from '@/utils/history';
import { clearUserData } from '@/utils/authorization';

import { DEV_GARAGE_ID } from '@/devConstants';

const user = new AuthEngine({
  baseURL: process.env.API_GATEWAY_URL!,
  authRedirectUrl: '/login',
  history,
  garageId: DEV_GARAGE_ID,
  // package's API instance (axios) needs to be compatible with app's one
  // TODO: update axios in API packages to match current axios version
  // @ts-ignore
  authApiProvider,
});

user.subscribe('onSignOut', clearUserData);

export default user;