import React from 'react';
import styled from 'styled-components';
import {
  Elements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  Breadcrumbs,
} from '@material-ui/core';
import flow from 'lodash/flow';

import {
  Button,
  Table,
  RowsMap,
  Link,
  ConfirmationModalContent,
  Card,
  Row,
  InfoButton,
  EditButton,
  DeleteButton,
  CardHead,
} from '@zero5/ui';
import vars from '@zero5/ui/lib/styles/vars';
import toast from '@zero5/ui/lib/utils/toast';

import { PaymentMethod } from '@/api/paymentMethod/models';
import usePaymentMethodsQuery from '@/api/paymentMethod/usePaymentMethodsQuery';
import useDeletePaymentMethodMutation from '@/api/paymentMethod/useDeletePaymentMethodMutation';
import useSetDefaultPaymentMutation from '@/api/paymentMethod/useSetDefaultPaymentMutation';

import Page from '@/components/common/Page';
import LoadingSpinner from '@/components/common/LoadingSpinner';
import CardWrapper from '@/components/payments/CardWrapper';
import PaymentMethodModal from '@/components/payments/PaymentMethodModal';
import NoPaymentMethods from '@/components/payments/NoPaymentMethods';
import { PageHeading } from '@/components/typography';

import useModal from '@/utils/hooks/useModal';
import { formatCard } from '@/utils/formatters/payment';
import { withAuth } from '@/utils/hocs/withAuth';
import { withLoading } from '@/utils/hocs/withLoading';

const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY!);

const PaymentContent: React.FC = () => {
  const [
    selectedPaymentMethods,
    setSelectedPaymentMethods,
  ] = React.useState<Array<PaymentMethod>>([]);

  const [
    AddPaymentMethodModal,
    openAddPaymentMethodModal,
    closeAddPaymentMethodModal,
  ] = useModal('addPaymentMethod');
  const [
    EditPaymentMethodModal,
    openEditPaymentMethodModal,
    closeEditPaymentMethodModal,
  ] = useModal<number>('editPaymentMethod');
  const [
    InfoPaymentMethodModal,
    openInfoPaymentMethodModal,
    closeInfoPaymentMethodModal,
  ] = useModal<number>('paymentMethod');
  const [
    DeletePaymentMethodModal,
    openDeletePaymentMethodModal,
    closeDeletePaymentMethodModal,
  ] = useModal<PaymentMethod>();
  const [
    DeletePaymentMethodsModal,
    openDeletePaymentMethodsModal,
    closeDeletePaymentMethodsModal,
  ] = useModal<number>();

  const {
    data: paymentMethodsData,
    isLoading: isPaymentLoading,
  } = usePaymentMethodsQuery();

  const deletePaymentMutation = useDeletePaymentMethodMutation({
    onSuccess: (_, variables) => {
      setSelectedPaymentMethods((prev) => prev.filter(({ id }) => id !== variables.paymentMethodId));
      closeDeletePaymentMethodModal();
      closeDeletePaymentMethodsModal();
    },
    onError: (error) => {
      toast('error', error.message || 'Error while deleting.');
    },
  });

  const setDefaultPaymentMutation = useSetDefaultPaymentMutation();

  const deletePaymentMethodHandler = React.useCallback((paymentMethodId: number) => {
    deletePaymentMutation.mutate({
      paymentMethodId: paymentMethodId,
    });
  }, [deletePaymentMutation]);

  const deletePaymentMethodsHandler = React.useCallback(() => {
    selectedPaymentMethods.forEach((paymentMethod) => {
      deletePaymentMutation.mutate({
        paymentMethodId: paymentMethod.id,
      });
    });
  }, [deletePaymentMutation, selectedPaymentMethods]);

  const paymentMethods: Array<PaymentMethod> = React.useMemo(() => {
    return paymentMethodsData || [];
  }, [paymentMethodsData]);

  const cardsTableRowsMap: RowsMap<PaymentMethod> = React.useMemo(() => [
    {
      title: '',
      id: 'isDefault',
      data: ({ isDefault }) => isDefault && <DefaultPaymentMethodText>Default</DefaultPaymentMethodText>,
      disableSorting: true,
    },
    {
      title: 'Card Number (Last 4 Digit)',
      id: 'card.last4',
      data: ({ card: { last4, brand } }) => {
        return (
          <CardWrapper brand={brand} last4={last4} />
        );
      },
    },
    {
      title: 'Expiration Date',
      id: 'card.exp_year',
      data: ({ card: { exp_month, exp_year } }) => `${exp_month}/${exp_year % 100}`,
    },
    {
      title: 'Info',
      id: 'info',
      data: (item) => (
        <InfoButton
          onClick={() => openInfoPaymentMethodModal(item.id)}
          disabled={deletePaymentMutation.isLoading}
        />
      ),
      CellProps: {
        width: '70px',
      },
      disableSorting: true,
    },
    {
      title: '',
      id: 'actions',
      disableSorting: true,
      data: (item) => (
        <Row gap="10px">
          <EditButton
            onClick={() => openEditPaymentMethodModal(item.id)}
            disabled={deletePaymentMutation.isLoading}
          />
          <DeleteButton
            onClick={() => {
              openDeletePaymentMethodModal(item);
            }}
            disabled={deletePaymentMutation.isLoading}
          />
        </Row>
      ),
      CellProps: {
        width: '105px',
      },
      HeadCellProps: {
        align: 'center',
      },
    },
  ], [
    deletePaymentMutation.isLoading,
    openDeletePaymentMethodModal,
    openEditPaymentMethodModal,
    openInfoPaymentMethodModal,
  ]);

  if (isPaymentLoading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {Boolean(paymentMethods.length) ? (
        <>
          <ButtonsRow gap="10px" justifyContent="flex-end">
            {selectedPaymentMethods.length === 1 && (
              <Button
                variant="outlined"
                disabled={setDefaultPaymentMutation.isLoading}
                onClick={() => setDefaultPaymentMutation.mutate({
                  paymentMethodId: selectedPaymentMethods[0].id,
                })}
              >
                Set as default
              </Button>
            )}
            {selectedPaymentMethods.length > 1 && (
              <Button
                variant="outlined"
                disabled={deletePaymentMutation.isLoading}
                onClick={() => openDeletePaymentMethodsModal(selectedPaymentMethods.length)}
              >
                Delete selected
              </Button>
            )}
            <Button
              onClick={() => openAddPaymentMethodModal()}
            >
              Add card
            </Button>
          </ButtonsRow>
          <Card>
            <CardHead
              title="Saved Payment Method"
            />
            <PaymentMethodsTable
              controls="checkbox"
              selected={selectedPaymentMethods}
              onItemSelect={
                (selectedItems) => setSelectedPaymentMethods(
                  Array.isArray(selectedItems) ? selectedItems : [selectedItems],
                )
              }
              rowsMap={cardsTableRowsMap}
              data={paymentMethods}
              size="small"
              minWidth="600px"
              withoutPagination
            />
          </Card>
        </>
      ) : (
        <NoPaymentMethods />
      )}

      <AddPaymentMethodModal
        fullScreenSize="800px"
      >
        <PaymentMethodModal
          mode="add"
          paymentMethodsCount={paymentMethods.length}
          onCancel={closeAddPaymentMethodModal}
        />
      </AddPaymentMethodModal>
      <EditPaymentMethodModal
        fullScreenSize="800px"
      >
        {({ data }) => (
          <PaymentMethodModal
            mode="edit"
            id={data}
            paymentMethodsCount={paymentMethods.length}
            onCancel={closeEditPaymentMethodModal}
          />
        )}
      </EditPaymentMethodModal>
      <InfoPaymentMethodModal
        fullScreenSize="800px"
      >
        {({ data }) => (
          <PaymentMethodModal
            mode="info"
            id={data}
            paymentMethodsCount={paymentMethods.length}
            onCancel={closeInfoPaymentMethodModal}
          />
        )}
      </InfoPaymentMethodModal>

      <DeletePaymentMethodModal>
        {({ data }) => (
          <ConfirmationModalContent
            text={`Are you sure you want to delete this card ${formatCard(data.card)}?`}
            onAccept={() => deletePaymentMethodHandler(data.id)}
            onReject={closeDeletePaymentMethodModal}
            acceptText='Delete'
            loading={deletePaymentMutation.isLoading}
          />
        )}
      </DeletePaymentMethodModal>
      <DeletePaymentMethodsModal>
        {({ data }) => (
          <ConfirmationModalContent
            text={`Are you sure you want to delete ${data} cards?`}
            onAccept={deletePaymentMethodsHandler}
            onReject={closeDeletePaymentMethodsModal}
            acceptText='Delete'
            loading={deletePaymentMutation.isLoading}
          />
        )}
      </DeletePaymentMethodsModal>
    </>
  );
};

const Payment: React.FC = () => {
  return (
    <Page>
      <StyledBreadcrumbs>
        <Link to="/user/account">Home</Link>
        <Link to="/user/permits">Payment Methods</Link>
      </StyledBreadcrumbs>
      <PageHeading>Payment Methods</PageHeading>
      <Elements
        stripe={stripePromise}
        options={{
          fonts: [{
            cssSrc: 'https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap',
          }],
        }}
      >
        <PaymentContent />
      </Elements>
    </Page>
  );
};

const ButtonsRow = styled(Row)`
  margin-bottom: 20px;
`;


const StyledBreadcrumbs = styled(Breadcrumbs)`
  margin-bottom: 10px;
`;

const PaymentMethodsTable = styled(Table)`
  td, th {
    text-align: left;
  }
` as typeof Table;

const DefaultPaymentMethodText = styled.span`
  color: ${vars.palette.primary};
`;

export default flow(
  withAuth(),
  withLoading(),
)(Payment);
