import React from 'react';
import styled from 'styled-components';

import { Skeleton } from '@zero5/ui';

import useSelfUserQuery from '@/api/user/useSelfUserQuery';

import AvatarImage from '@/assets/icons/avatar.inline.svg';

interface Props {}

const UserInfo: React.FC<Props> = () => {
  const selfUserQuery = useSelfUserQuery();

  return (
    <Wrapper>
      <AvatarImage />
      <Name>
        {selfUserQuery.isLoading ? (
          <Skeleton width="100px" />
        ) : `${selfUserQuery.data?.user.firstName} ${selfUserQuery.data?.user.lastName}`}
      </Name>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  overflow: hidden;

  & > * {
    flex-shrink: 0;
  }
`;

const Name = styled.span`
  white-space: nowrap;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
`;

export default UserInfo;