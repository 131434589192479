import { useMutation, UseMutationOptions } from 'react-query';

import { endUserResendTemporaryPassword } from '@zero5/auth-api';

import { handleError } from '@/utils/handleError';

type Response = {
  destination: string;
  deliveryMedium: 'SMS' | 'EMAIL';
};
type Params = {
  email: string;
};

const useResendTemporaryPasswordMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'resendTemporaryPassword',
    async ({ email }) => {
      const response = await endUserResendTemporaryPassword({
        email,
      });

      return response;
    },
    {
      ...options,
      onError: (error) => {
        handleError(error, 'Failed to resend temporary password!');
      },
    },
  );
};

export default useResendTemporaryPasswordMutation;
