/* eslint-disable max-len */
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { Button, Input } from '@zero5/ui';
import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';

import Zero5Logo from '@/assets/images/zero5_logo.png';
import PlanetImage from '@/assets/images/planet.svg';
import MagicIcon from '@/assets/icons/magic.svg';
import CogsIcon from '@/assets/icons/cogs.svg';
import GlobeIcon from '@/assets/icons/globe.svg';
import PowerImage from '@/assets/images/power.svg';
import StreamlinedImage from '@/assets/images/streamlined.svg';
import SmartCityImage from '@/assets/images/smart_city.svg';
import LinkedinIcon from '@/assets/icons/linkedin_in.inline.svg';
import FacebookIcon from '@/assets/icons/facebook_f.inline.svg';
import AngellistIcon from '@/assets/icons/angellist.inline.svg';
import GoogleIcon from '@/assets/icons/google.inline.svg';

const headerNavigationLinks = [
  {
    title: 'Home',
    href: '#home',
  },
  {
    title: 'Solutions',
    href: '#solutions',
  },
  {
    title: 'About',
    href: '#about',
  },
  {
    title: 'Contact',
    href: '#contact',
  },
  {
    title: 'Sign in',
    href: '/login',
  },
];

const features = [
  {
    icon: MagicIcon,
    title: 'Automate',
    // eslint-disable-next-line max-len
    text: 'Automate the parking process and operation for both drivers and garages. Manual process belongs in the past. Let’s put it there.',
  },
  {
    icon: CogsIcon,
    title: 'Build',
    // eslint-disable-next-line max-len
    text: 'Build a comprehensive parking network that collects, analyzes, and distributes on-demand real-time data accurately. Transform data into information.',
  },
  {
    icon: GlobeIcon,
    title: 'Connect',
    text: 'Connect available spots, drivers, and vehicles for seamless parking experience for today and tomorrow.',
  },
];

const solutions: Array<{
  title: string;
  text: string;
  image: string;
  alt: string;
  href: string;
  reverse?: boolean;
}> = [
  {
    title: 'Streamlined parking experience for all.',
    // eslint-disable-next-line max-len
    text: 'Automation means efficiency. We help maximize your revenue by reducing operating costs and connecting your unused spots with customers who are looking for parking.',
    image: StreamlinedImage,
    alt: 'power',
    href: '#',
  },
  {
    title: 'More power to you.',
    // eslint-disable-next-line max-len
    text: 'Our easy-to-use cloud-based tools will be your best friends. We will digitize all your data and make it available to you 24/7. If you need real-time data to understand usage or to make business decisions, there is a tool for that.',
    image: PowerImage,
    alt: 'streamlined',
    href: '#',
    reverse: true,
  },
  {
    title: 'Smart City. Together.',
    // eslint-disable-next-line max-len
    text: 'If you think parking can and should be better, you are not alone. Smartphones came first, and next is the smart cities. Let’s build the Cities of Tomorrow together.',
    image: SmartCityImage,
    alt: 'smart city',
    href: '#',
  },
];

const socialNavigationLinks = [
  {
    icon: LinkedinIcon,
    alt: 'linkedin',
    href: 'https://www.linkedin.com/company/zero5/about/?viewAsMember=true',
  },
  {
    icon: FacebookIcon,
    alt: 'facebook',
    href: '#',
  },
  {
    icon: AngellistIcon,
    alt: 'angellist',
    href: 'https://angel.co/zero5',
  },
  {
    icon: GoogleIcon,
    alt: 'google',
    href: '#',
  },
];

const Landing: React.FC = () => {
  const [isMenuOpen, , , toggleMenu] = useToggle(false);

  return (
    <>
      <Header>
        <Navigation>
          <HeaderWrapper>
            <LogoImage src={Zero5Logo} alt="zero5" />
            <Hamburger isMenuOpen={isMenuOpen} onClick={toggleMenu}>
              <div />
              <div />
              <div />
            </Hamburger>
          </HeaderWrapper>
          <NavigationList isMenuOpen={isMenuOpen}>
            {headerNavigationLinks.map(({ title, href }) => (
              <NavigationItem key={title}>
                {href.startsWith('#') ? (
                  <NavigationInnerLink href={href}>{title}</NavigationInnerLink>
                ) : (
                  <NavigationLink to={href}>{title}</NavigationLink>
                )}
              </NavigationItem>
            ))}
          </NavigationList>
        </Navigation>
      </Header>
      <Wrapper>
        <HeroSection id="home">
          <HeroContent>
            <MainTitle>
              Painless parking for today.
              <br />
              Autonomous valet for the future.
            </MainTitle>
            <MainSubtitle>Automate. Build. Connect.</MainSubtitle>
            <HeroButton variant="contained" color="primary">learn more</HeroButton>
          </HeroContent>
          <img src={PlanetImage} alt="planet" />
        </HeroSection>
        <FeaturesSection id="solutions">
          <FeaturesList>
            {features.map(({ icon, title, text }) => (
              <FeaturesItem>
                <FeaturesItemHeader>
                  <img src={icon} alt={title} />
                  <FeaturesTitle>{title}</FeaturesTitle>
                </FeaturesItemHeader>
                <FeaturesText>{text}</FeaturesText>
              </FeaturesItem>
            ))}
          </FeaturesList>
        </FeaturesSection>
        <SolutionsSection>
          <SolutionsList>
            {solutions.map(({
              title,
              text,
              image,
              alt,
              href,
              reverse,
            }) => (
              <SolutionsItem reverse={reverse} key={title}>
                <SolutionsContent>
                  <SolutionsTitle>{title}</SolutionsTitle>
                  <SolutionsText>{text}</SolutionsText>
                  <SolutionsLink to={href}>Learn more</SolutionsLink>
                </SolutionsContent>
                <img src={image} alt={alt} />
              </SolutionsItem>
            ))}
          </SolutionsList>
        </SolutionsSection>
      </Wrapper>
      <AboutSection id="about">
        <Wrapper>
          <AboutContent>
            <AboutTitle>About</AboutTitle>
            <AboutText>
              When we saw an opportunity to simplify our lives, we started
              {' '}
              <span>zero5</span>
              {' '}
              and
              began a mission to challenge status quo and build a better future. We
              are working relentlessly to serve this purpose. We are starting with
              building technology to streamline parking of today. Next, we will truly
              bring the offline parking market online. We will reveal the potential
              of the parking market and change the way the world thinks about parking.
              If you want to learn more, get in touch with us!
            </AboutText>
          </AboutContent>
        </Wrapper>
      </AboutSection>
      <Wrapper>
        <ContactUsSection id="contact">
          <ContactUsTitle>Contact Us</ContactUsTitle>
          <ContactForm action="https://formspree.io/team@zero5.co" method="POST">
            <ContactFormInput
              label="Name"
              columns={6}
            />
            <ContactFormInput
              label="Email"
              columns={6}
            />
            <ContactFormInput
              label="Subject"
              columns={12}
            />
            <ContactFormInput
              label="Message"
              // multiline
              // rows={7}
              columns={12}
            />
            <ContactButtonWrapper>
              <ContactButton variant="contained" color="primary" type="submit">send</ContactButton>
            </ContactButtonWrapper>
          </ContactForm>
        </ContactUsSection>
        <Footer>
          <FooterNavigation>
            <FooterNavigationList>
              <FooterNavigationItem>
                <FooterNavigationLink to="/policies/privacy-policy">Privacy Statement</FooterNavigationLink>
              </FooterNavigationItem>
              <FooterNavigationItem>
                <FooterNavigationLink to="/policies/terms-of-use">Terms of use</FooterNavigationLink>
              </FooterNavigationItem>
            </FooterNavigationList>
          </FooterNavigation>
          <FooterSocial>
            <FooterSocialList>
              {socialNavigationLinks.map(({ icon: Icon, alt, href }) => (
                <FooterSocialItem key={alt}>
                  <FooterSocialLink href={href} target="_blank">
                    <Icon />
                  </FooterSocialLink>
                </FooterSocialItem>
              ))}
            </FooterSocialList>
          </FooterSocial>
          <Copyright>{`Copyright ${new Date().getFullYear()}, zero5, Inc. All Rights Reserved.`}</Copyright>
        </Footer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  max-width: 1040px;
  padding: 0 15px;
  margin: 0 auto;

  @media (max-width: 1190px) {
    max-width: 860px;
  }

  @media (max-width: 990px) {
    max-width: 620px;
  }

  @media (max-width: 765px) {
    max-width: 440px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
    padding: 0 50px;
  }
`;

const Header = styled.header`
  width: 100vw;
  padding: 20px 0;
  position: fixed;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 10;
`;

const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1100px;
  padding: 0 15px;
  margin: 0 auto;
  box-sizing: content-box;

  @media (max-width: 1200px) {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 990px) {
    max-width: 690px;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 770px) {
    max-width: 510px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }
`;

const HeaderWrapper = styled.div`
  @media (max-width: 990px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
`;

const LogoImage = styled.img`
  width: 75px;
`;

const Hamburger = styled.button<{ isMenuOpen: boolean }>`
  display: none;
  width: 41px;
  height: 37px;
  padding: 2px;
  background-color: transparent;
  border: none;
  cursor: pointer;

  & > div {
    width: 23px;
    height: 3px;
    margin: 5px;
    background-color: #000000;
    transition: transform .35s, opacity .35s;

    &:first-child {
      transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(-45deg) translate(-5px, 6px)' : 'none')};
    }

    &:nth-child(2) {
      opacity: ${({ isMenuOpen }) => (isMenuOpen ? '0' : '1')};
    }

    &:last-child {
      transform: ${({ isMenuOpen }) => (isMenuOpen ? 'rotate(45deg) translate(-5px, -6px)' : 'none')};
    }
  }

  @media (max-width: 990px) {
    display: block;
  }
`;

const NavigationList = styled.ul<{ isMenuOpen: boolean }>`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 990px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: ${({ isMenuOpen }) => (isMenuOpen ? '180px' : '0')};
    transition: height .35s;
    overflow-y: hidden;
    flex-direction: column;
  }
`;

const NavigationItem = styled.li`
  &:not(:last-child) {
    margin-right: 14px;

    @media (max-width: 990px) {
      margin-right: 0;
    }
  }
`;

const linkStyles = css`
  color: #000000;
  text-decoration: none;
  font-size: 14px;

  &:hover {
    color: #19e6a0;
  }
`;

const NavigationLink = styled(Link)`
  ${linkStyles}

  padding: 8px 16px;

  @media (max-width: 990px) {
    display: block;
    padding: 8px 0;
    line-height: 20px;
  }
`;

const NavigationInnerLink = styled.a`
  ${linkStyles}

  padding: 8px 16px;

  @media (max-width: 990px) {
    display: block;
    padding: 8px 0;
    line-height: 20px;
  }
`;

const HeroSection = styled.section`
  display: flex;
  align-items: center;
  min-height: 655px;
  padding: 65px 0;
  position: relative;

  @media (max-width: 1200px) {
    max-width: 930px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 990px) {
    max-width: 690px;
  }

  @media (max-width: 770px) {
    max-width: 510px;
  }

  @media (max-width: 575px) {
    max-width: 100%;
  }

  img {
    max-width: 575px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;

    @media (max-width: 1200px) {
      max-width: 475px;
    }

    @media (max-width: 990px) {
      max-width: 345px;
    }

    @media (max-width: 765px) {
      max-width: 445px;
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
    }

    @media (max-width: 765px) {
      max-width: 100%;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

const HeroContent = styled.div`

`;

const MainTitle = styled(Typography).attrs({
  variant: 'h1',
})`
  margin-bottom: 30px;
  color: #000000;
  font-weight: 700;
  font-size: 50px;
  text-transform: none;

  @media (max-width: 765px) {
    font-size: 40px;
  }
`;

const MainSubtitle = styled(Typography).attrs({
  variant: 'h3',
})`
  margin-bottom: 40px;
  color: #000000;
  font-weight: 400;
  font-size: 19px;
  text-transform: none;
`;

const HeroButton = styled(Button)`
  
`;

const FeaturesSection = styled.section`
  padding: 65px 0;
  margin-bottom: 75px;
`;

const FeaturesList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 765px) {
    flex-direction: column;
  }
`;

const FeaturesItem = styled.li`
  padding: 15px;
`;

const FeaturesItemHeader = styled.div`
  display: flex;
  margin-bottom: 10px;

  @media (max-width: 575px) {
    flex-direction: column;
    align-items: center;
  }

  img {
    width: 20px;
    margin-right: 15px;
  }
`;

const FeaturesTitle = styled(Typography)`
  font-size: 24px;
  font-weight: 600;
  color: #000000;
`;

const FeaturesText = styled(Typography)`
  font-size: 16px;
  color: #000000;
`;

const SolutionsSection = styled.section`
  margin-bottom: 50px;
`;

const SolutionsList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const SolutionsItem = styled.li<{ reverse?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};

  &:not(:last-child) {
    margin-bottom: 100px;
  }

  @media (max-width: 765px) {
    flex-direction: column;
  }

  img {
    width: calc(50% - 20px);

    @media (max-width: 765px) {
      width: 100%;
    }
  }
`;

const SolutionsContent = styled.div`
  width: calc(50% - 20px);

  @media (max-width: 765px) {
    width: 100%;
  }
`;

const SolutionsTitle = styled(Typography)`
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  color: #000000;
`;

const SolutionsText = styled(Typography)`
  margin-bottom: 15px;
  font-size: 16px;
  color: #000000;
`;

const SolutionsLink = styled(Link)`
  color: #21ce99;
  font-size: 14px;
  text-decoration: none;

  &:hover, &:focus {
    color: #19e6a0;
  }
`;

const AboutSection = styled.section`
  padding: 100px 0;
  margin-bottom: 100px;
  background-color: #000000;
  color: #ffffff;
`;

const AboutContent = styled.div`
  width: 50%;

  @media (max-width: 990px) {
    width: auto;
  }
`;

const AboutTitle = styled(Typography)`
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 24px;
`;

const AboutText = styled(Typography)`
  font-size: 16px;

  span {
    font-weight: 600;
    color: #21ce99;
  }
`;

const ContactUsSection = styled.section`
  margin-bottom: 50px;
`;

const ContactUsTitle = styled(Typography)`
  margin-bottom: 50px;
  font-weight: 600;
  font-size: 24px;
`;

const ContactForm = styled.form`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
`;

const ContactFormInput = styled(Input)<{ columns: number }>`
  grid-column: span ${({ columns }) => columns};

  @media (max-width: 765px) {
    grid-column: span 12;
  }
`;

const ContactButtonWrapper = styled.div`
  grid-column: span 12;
`;

const ContactButton = styled(Button)`
  
`;

const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  border-top: 1px solid rgba(0,0,0,.1);

  @media (max-width: 765px) {
    flex-direction: column;
  }
`;

const FooterNavigation = styled.nav`
  
`;

const FooterNavigationList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const FooterNavigationItem = styled.li`
  
`;

const FooterNavigationLink = styled(Link)`
  display: block;
  padding: 5px;
  font-size: 14px;
  color: #000000;
  text-decoration: none;

  &:hover {
    color: #19e6a0;
  }
`;

const FooterSocial = styled.div`
  
`;

const FooterSocialList = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
`;

const FooterSocialItem = styled.li`
`;

const FooterSocialLink = styled.a`
  ${linkStyles}

  display: block;
  padding: 5px 15px;

  svg {
    height: 14px;
  }
`;

const Copyright = styled.small`
  padding: 5px;
`;

export default Landing;
