/* eslint-disable import/prefer-default-export */
export const dateMask = (value: string, separator: string) => {
  let result = '';

  const valueLocal = value.replace(separator, '');

  if (!valueLocal.length) {
    return result;
  }

  if (!valueLocal.match(/^\d*$/g)) {
    return;
  }

  const month = valueLocal.slice(0, 2);

  if (parseInt(month, 10) === 0) {
    return '0';
  }

  if (parseInt(month, 10) <= 12) {
    result = parseInt(month[0], 10) > 1 ? `0${month}` : month;
  } else {
    result = `0${month}`;
  }

  result = `${result}${valueLocal.slice(2, 4)}`.slice(0, 4);

  if (result.length >= 3) {
    result = `${result.slice(0, 2)}${separator}${result.slice(2, 4)}`;
  }

  return result;
};
