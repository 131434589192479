export const paymentHistoryQueryKeys = {
  base: ['paymentHistory'] as const,
  list: (params: {
    page?: number;
    take?: number;
    orderBy?: 'garageName' | 'receipt' | 'date' | 'item' | 'paymentStatus' | 'amount' | 'default';
    direction?: 'DESC' | 'ASC';
  }) => [
    ...paymentHistoryQueryKeys.base,
    'list',
    params.page,
    params.take,
    params.orderBy,
    params.direction,
  ] as const,
  receipt: (transactionId: string) => [...paymentHistoryQueryKeys.base, 'receipt', transactionId],
  csv: (transactionIds: Array<string>) => [...paymentHistoryQueryKeys.base, 'receipt', 'csv', ...transactionIds],
};
