import {
  createStore,
  applyMiddleware,
  compose,
  combineReducers,
} from 'redux';
import thunk from 'redux-thunk';

import { WorkspaceActions } from './actions/workspace';
import workspaceReducer from './reducers/workspace';
import authReducer from './reducers/auth';

const rootReducer = combineReducers({
  workspaceReducer,
  authReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

export type State = ReturnType<typeof rootReducer>;
export type Actions = WorkspaceActions;

export default createStore(rootReducer, enhancer);
