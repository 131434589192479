import { useMutation, UseMutationOptions } from 'react-query';

import { getDriverPostReceiptCsvReport } from '@zero5/payment-api';

type Response = string;
type Params = {
  transactionIds: Array<string>;
};

const usePaymentReceiptCsvMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'attachPaymentMethod',
    async (params) => {
      const response = await getDriverPostReceiptCsvReport(params.transactionIds);

      return response.report;
    },
    {
      ...options,
    },
  );
};

export default usePaymentReceiptCsvMutation;

