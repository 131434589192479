import { Z5DateInterface, z5DateModule } from '@zero5/time-module';

type AcceptableDate = Date | number | string;

// uncomment when need to use
// const acceptableDateToDate = (date: AcceptableDate) => {
//   if (date instanceof Date) return date;

//   return new Date(date);
// };

const acceptableDateToTimestamp = (date: AcceptableDate) => {
  if (date instanceof Date) return date.getTime();

  return new Date(date).getTime();
};

// uncomment when need to use
// const acceptLocalTimeAsTimezone = (date: Date) => z5DateModule.convertLocalToConfiguredTimezone(date);

const acceptLocalTime = (timestamp: number) => z5DateModule.fromUtc(timestamp);

const dateMonthYearFormatter = (module: Z5DateInterface) => module.format('MM/dd/yy');

const fullMonthDateYearFormatter = (module: Z5DateInterface) => module.format('MMM d, yyyy');

export const getTimestampOfTodayStart = () => acceptLocalTime(Date.now()).dayStart().getTimestamp();

export const formatDateMonthYear = (date: AcceptableDate) =>
  dateMonthYearFormatter(acceptLocalTime(acceptableDateToTimestamp(date)));

export const formatFullMonthDateYear = (date: AcceptableDate) =>
  fullMonthDateYearFormatter(acceptLocalTime(acceptableDateToTimestamp(date)));
