import React from 'react';

import VisaIcon from '@/assets/icons/payments/visa.svg';
import MastercardIcon from '@/assets/icons/payments/mastercard.svg';
import AmericanExpress from '@/assets/icons/payments/american_express.png';
import DinersClub from '@/assets/icons/payments/diners_club.jpg';
import DiscoverIcon from '@/assets/icons/payments/discover.jpg';
import JCBIcon from '@/assets/icons/payments/jcb.svg';
import UnionpayIcon from '@/assets/icons/payments/unionpay.png';

interface Props {
  brand: string;
}

const brandToData: Record<
string,
React.ImgHTMLAttributes<HTMLImageElement>
> = {
  visa: {
    src: VisaIcon,
    alt: 'visa',
  },
  amex: {
    src: AmericanExpress,
    alt: 'american express',
  },
  mastercard: {
    src: MastercardIcon,
    alt: 'mastercard',
  },
  discover: {
    src: DiscoverIcon,
    alt: 'discover',
  },
  diners: {
    src: DinersClub,
    alt: 'diners club',
  },
  jcb: {
    src: JCBIcon,
    alt: 'jcb',
  },
  unionpay: {
    src: UnionpayIcon,
    alt: 'union pay',
  },
};

const CardBrandImage: React.FC<Props> = ({ brand, ...props }) => {
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img width="45" {...brandToData[brand]} {...props} />;
};

export default CardBrandImage;
