import React, { Suspense } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useFormik } from 'formik';
import QRCode from 'qrcode.react';

import {
  AutocompleteInput,
  Button,
  Checkbox,
  Column,
  Columns,
  Input,
  InputRoot,
  ModalTitle,
  Row,
  Select,
  Skeleton,
  Switch,
} from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';
import toast from '@zero5/ui/lib/utils/toast';
import vars from '@zero5/ui/lib/styles/vars';

import usePaymentMethodsQuery from '@/api/paymentMethod/usePaymentMethodsQuery';
import usePermitEntryQuery from '@/api/permit/usePermitEntryQuery';
import useAcceptPermitInviteMutation from '@/api/permit/useAcceptPermitInviteMutation';
import useUpdatePermitMutation from '@/api/permit/useUpdatePermitMutation';

import ModalTitleButton from '@/components/common/ModalTitleButton';

import useDateModule from '@/utils/date/useDateModule';
import { formatCard } from '@/utils/formatters/payment';

import NoteImage from '@/assets/images/note.png';

import DatePicker from '../common/DatePicker';

import PermitInviteStatus from './PermitInviteStatus';

const validationSchema = yup.object().shape({
  startDate: schemas.dateSchema.label('Start Date'),
  autoRenew: yup.boolean(),
  lpNum: yup.string().required().label('License Plate'),
  state: yup.string().required().label('State'),
  vehicleMake: yup.string().required().label('Vehicle Make'),
  vehicleModel: yup.string().required().label('Vehicle Model'),
  vehicleColor: yup.string().required().label('Vehicle Color'),
  paymentMethod: yup.string().required().label('Payment Method'),
  agreeNoRefundPolicy: yup.boolean().isTrue().label('Agreement'),
});

type DataProps = {
  mode: 'add';
  id: number;
} | {
  mode: 'info';
  id: number;
} | {
  mode: 'edit';
  id: number;
};

type CommonProps = {
  onCancel: () => void;
};

type Props = CommonProps & DataProps;

const PermitLoader: React.FC<Props> = ({ ...props }) => {
  return (
    <Suspense fallback={<ModalSkeleton variant="rect" />}>
      <PermitModal {...props} />
    </Suspense>
  );
};

const ModalSkeleton = styled(Skeleton)`
  width: 875px;
  height: 519px;

  @media (max-width: 1075px) {
    width: 560px;
    height: 796px;
  }

  @media (max-width: 660px) {
    width: 100%;
    height: 100%;
  }
`;

const PermitModal: React.FC<Props> = (props) => {
  const {
    id,
    mode: modeProps,
    onCancel,
  } = props;

  const {
    getTimestampOfTodayStart,
  } = useDateModule();

  const [mode, setMode] = React.useState(modeProps);

  const permitInviteQuery = usePermitEntryQuery({
    permitInviteId: id,
  }, {
    suspense: true,
    onError: () => {
      toast('error', 'Permit Invite doesn`t exist');
      onCancel();
    },
  });

  const paymentMethods = usePaymentMethodsQuery();

  const acceptPermitInviteMutation = useAcceptPermitInviteMutation();
  const updatePermitInviteMutation = useUpdatePermitMutation();

  const formik = useFormik({
    initialValues: mode !== 'add' ? {
      startDate: new Date(permitInviteQuery.data!.permit!.startTime),
      autoRenew: permitInviteQuery.data!.permit!.isAutoRenewEnabled,
      lpNum: permitInviteQuery.data!.vehicle!.lpNum,
      state: permitInviteQuery.data!.vehicle!.lpState,
      vehicleMake: permitInviteQuery.data!.vehicle!.make,
      vehicleModel: permitInviteQuery.data!.vehicle!.model,
      vehicleColor: permitInviteQuery.data!.vehicle!.color,
      paymentMethod: permitInviteQuery.data!.permit!.paymentId,
      agreeNoRefundPolicy: true,
    } : {
      startDate: new Date(getTimestampOfTodayStart()),
      autoRenew: true,
      lpNum: '',
      state: '',
      vehicleMake: '',
      vehicleModel: '',
      vehicleColor: '',
      paymentMethod: paymentMethods.data?.find((pm) => pm.isDefault)?.id,
      agreeNoRefundPolicy: false,
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values) => {
      if (mode === 'add') {
        await acceptPermitInviteMutation.mutateAsync({
          permitInviteId: permitInviteQuery.data!.permitInvite.permitInviteId,
          paymentId: values.paymentMethod!,
          startTime: values.startDate.getTime(),
          isAutoRenewEnabled: values.autoRenew,
          vehicle: {
            lpNum: values.lpNum,
            lpState: values.state,
            color: values.vehicleColor,
            make: values.vehicleMake,
            model: values.vehicleModel,
          },
        });
      }

      if (mode === 'edit') {
        await updatePermitInviteMutation.mutateAsync({
          permitId: permitInviteQuery.data!.permit!.permitId,
          garageId: permitInviteQuery.data!.permitInvite.permitType.garageId,
          paymentId: values.paymentMethod!,
          isAutoRenewEnabled: values.autoRenew,
          vehicle: {
            lpNum: values.lpNum,
            lpState: values.state,
            color: values.vehicleColor,
            make: values.vehicleMake,
            model: values.vehicleModel,
          },
        });
      }

      onCancel();
    },
  });

  const transformedInputChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, transformedValue: string) => {
      formik.setFieldValue(e.target.name, transformedValue);
    },
    [formik],
  );

  return (
    <Columns columnFrom="1075px">
      <SideContentColumn>
        <Row gap="25px">
          <TitleWrapper>
            {mode === 'add' && <ModalTitle>Purchase New Permit</ModalTitle>}
            {mode === 'info' && (
              <ModalTitleButton
                onClick={() => setMode('edit')}
              >
                Permit Information
              </ModalTitleButton>
            )}
            {mode === 'edit' && <ModalTitle>Edit Permit Information</ModalTitle>}
          </TitleWrapper>
          <PermitInviteStatus status={permitInviteQuery.data!.permitInvite.status} />
        </Row>
        <AccentText>
          * Message to show what action needs to be taken
        </AccentText>
        <CenteredBox>
          {mode === 'add' ? (
            <CardsImageComponent src={NoteImage} />
          ) : (
            <QRCode size={200} value={permitInviteQuery.data!.permit!.qr!} />
          )}
        </CenteredBox>
      </SideContentColumn>
      <MainContentColumn>
        <StyledForm onSubmit={formik.handleSubmit}>
          <Grid>
            <GridItem columns={6}>
              <Input
                label="Garage Name"
                value={permitInviteQuery.data?.permitInvite.permitType.garageId}
                disabled
              />
            </GridItem>
            <GridItem columns={6}>
              <Input
                label="Permit Type"
                value={permitInviteQuery.data?.permitInvite.permitType.name}
                poppingInfo={{
                  content: (
                    <>
                      Blanditiis dolores qui<br />
                      officia. Et quibusdam<br />
                      adipisci praesentium.<br />
                      Labore earum sunt<br />
                      perferendis non culpa.
                    </>
                  ),
                }}
                disabled
              />
            </GridItem>
            <GridItem columns={6}>
              <DatePicker
                label="Start Date"
                pickerProps={{
                  selected: formik.values.startDate,
                  onChange: (date) => {
                    formik.setFieldValue('startDate', date);
                  },
                  disabled: mode !== 'add',
                }}
                error={formik.touched.startDate && Boolean(formik.errors.startDate)}
                helperText={formik.touched.startDate ? formik.errors.startDate as string : undefined}
              />
            </GridItem>
            <GridItem columns={6}>
              <StyledSwitch
                label="Auto-Renew"
                onChange={(ignoredEvent, isChecked) => {
                  formik.setFieldValue('autoRenew', isChecked);
                }}
                disabled={mode === 'info'}
                checked={formik.values.autoRenew}
              />
            </GridItem>
            <GridItem columns={6}>
              <Input
                color="primary"
                label="License Plate Number"
                onChange={transformedInputChangeHandler}
                name="lpNum"
                value={formik.values.lpNum}
                disabled={mode === 'info'}
                error={formik.touched.lpNum && Boolean(formik.errors.lpNum)}
                helperText={formik.touched.lpNum ? formik.errors.lpNum : undefined}
                transform="uppercase"
              />
            </GridItem>
            <GridItem columns={6}>
              <AutocompleteInput
                label="License Plate State"
                onChangeValue={(value) => formik.setFieldValue('state', value)}
                optionVariant="main"
                placeVariant="state"
                value={formik.values.state}
                disabled={mode === 'info'}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state ? formik.errors.state : undefined}
              />
            </GridItem>
            <GridItem columns={4}>
              <Input
                color="primary"
                label="Vehicle Make"
                onChange={transformedInputChangeHandler}
                name="vehicleMake"
                value={formik.values.vehicleMake}
                disabled={mode === 'info'}
                error={formik.touched.vehicleMake && Boolean(formik.errors.vehicleMake)}
                helperText={formik.touched.vehicleMake ? formik.errors.vehicleMake : undefined}
                transform="uppercase"
              />
            </GridItem>
            <GridItem columns={4}>
              <Input
                color="primary"
                label="Vehicle Model"
                onChange={transformedInputChangeHandler}
                name="vehicleModel"
                value={formik.values.vehicleModel}
                disabled={mode === 'info'}
                error={formik.touched.vehicleModel && Boolean(formik.errors.vehicleModel)}
                helperText={formik.touched.vehicleModel ? formik.errors.vehicleModel : undefined}
                transform="uppercase"
              />
            </GridItem>
            <GridItem columns={4}>
              <Input
                color="primary"
                label="Vehicle Color"
                onChange={transformedInputChangeHandler}
                name="vehicleColor"
                value={formik.values.vehicleColor}
                disabled={mode === 'info'}
                error={formik.touched.vehicleColor && Boolean(formik.errors.vehicleColor)}
                helperText={formik.touched.vehicleColor ? formik.errors.vehicleColor : undefined}
                transform="capitalize"
              />
            </GridItem>
            <GridItem columns={6}>
              <Select
                label="Payment"
                disabled={mode === 'info'}
                options={paymentMethods.data}
                getOptionLabel={(option) => formatCard(option.card)}
                getOptionValue={(option) => option.id.toString()}
                value={paymentMethods.data?.find((pm) => pm.id === formik.values.paymentMethod)}
                onChange={(option) => formik.setFieldValue('paymentMethod', option!.id)}
              />
            </GridItem>
            <GridItem columns={6}>
              <InputRoot label="Permit Price">
                <Price>{formatPrice(permitInviteQuery.data?.permitInvite.permitType.price || 0)}</Price>
              </InputRoot>
            </GridItem>
          </Grid>
          {mode === 'add' && (
            <AgreementCheckbox
              label="I acknowledge that there is no refund or return after purchasing a permit."
              onChange={(e, value) => formik.setFieldValue('agreeNoRefundPolicy', value)}
              error={formik.touched.agreeNoRefundPolicy && Boolean(formik.errors.agreeNoRefundPolicy)}
            />
          )}
          {mode === 'info' && (
            <ButtonsRow gap="10px" justifyContent="flex-end">
              <Button
                type="button"
                onClick={onCancel}
              >
                Close
              </Button>
            </ButtonsRow>
          )}
          {mode === 'add' && (
            <ButtonsRow gap="10px" justifyContent="flex-end">
              <Button
                variant="outlined"
                type="button"
                disabled={formik.isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!formik.dirty || !formik.values.agreeNoRefundPolicy}
                loading={formik.isSubmitting}
              >
                Purchase
              </Button>
            </ButtonsRow>
          )}
          {mode === 'edit' && (
            <ButtonsRow gap="10px" justifyContent="flex-end">
              <Button
                variant="outlined"
                type="button"
                disabled={formik.isSubmitting}
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                disabled={!formik.dirty}
                loading={formik.isSubmitting}
              >
                Save
              </Button>
            </ButtonsRow>
          )}
        </StyledForm>
      </MainContentColumn>
    </Columns>
  );
};

const SideContentColumn = styled(Column)`
  display: grid;
  grid-template-rows: min-content min-content 1fr;
  align-items: center;
  gap: 20px;

  min-width: 300px;
`;

const AccentText = styled.span`
  color: ${vars.palette.danger};
  font-style: italic;
`;

const MainContentColumn = styled(Column)`
  width: 560px;

  @media (max-width: 660px) {
    width: 100%;
  }
`;

const TitleWrapper = styled.div`
  width: 200px;
`;

const CenteredBox = styled.div`
  margin: auto;
`;

const CardsImageComponent = styled.img`
  width: 202px;
  height: 235px;
  margin: auto;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-gap: 20px;
  margin-bottom: 20px;
`;

const GridItem = styled.div<{ columns: number; }>`
  grid-column: span ${({ columns }) => columns};

  @media (max-width: 660px) {
    grid-column: span 12;
  }
`;

const StyledSwitch = styled(Switch)`
  height: 40px;
  margin-top: 27px;
  display: flex;
  align-items: center;
`;

const StyledForm = styled.form`
  width: 100%;
`;

const Price = styled.div`
  color: #A2A2A2;
  font-size: 24px;
  line-height: 36px;
  text-align: right;
`;

const AgreementCheckbox = styled(Checkbox)`
  width: auto;

  span {
    white-space: normal;
  }
`;

const ButtonsRow = styled(Row)`
  padding-top: 30px;
  margin-top: auto;
`;

export default PermitLoader;
