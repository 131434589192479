import React from 'react';

import Status from '@zero5/ui/lib/Status';

import { PermitInviteStatus as PermitInviteStatusType } from '@/api/permit/models';

interface Props {
  status: PermitInviteStatusType;
}

const mapPermitStatusToStatusProps: Record<Exclude<PermitInviteStatusType, 'PENDING' | 'EXPIRED'>, {
  color: string;
  type: 'regular';
  label: string;
}> & {
  PENDING: {
    type: 'error';
    label: string;
  },
  EXPIRED: {
    type: 'error';
    label: string;
  },
} = {
  PENDING: {
    type: 'error',
    label: 'Action Needed',
  },
  EXPIRED: {
    type: 'error',
    label: 'Expired',
  },
  ACCEPTED: {
    color: '#386344',
    type: 'regular',
    label: 'Active',
  },
};

const PermitInviteStatus: React.FC<Props> = ({ status }) => {
  return (
    <Status
      {...mapPermitStatusToStatusProps[status]}
    />
  );
};

export default PermitInviteStatus;
