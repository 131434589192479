import { useQuery, UseQueryOptions } from 'react-query';

import { getPaymentHistory } from '@zero5/payment-api';

import { paymentHistoryQueryKeys } from './queryKeys';
import { mapPaymetnHistoryReport } from './mappings';

type Response = ReturnType<typeof mapPaymetnHistoryReport>;
type Params = {
  page?: number;
  take?: number;
  orderBy?: 'garageName' | 'receipt' | 'date' | 'item' | 'paymentStatus' | 'amount' | 'default';
  direction?: 'DESC' | 'ASC';
};
type Key = ReturnType<typeof paymentHistoryQueryKeys.list>;

const usePaymentHistoriesQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    paymentHistoryQueryKeys.list(params),
    async () => {
      const response = await getPaymentHistory(params);

      return mapPaymetnHistoryReport(response.paymentHistory);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePaymentHistoriesQuery;
