import React from 'react';
import ReactDOM from 'react-dom';

import { initializeRest as initializeAuthApi } from '@zero5/auth-api';
import { initializeRest as initializePaymentApi } from '@zero5/payment-api';
import { initialize as initializePermitApi } from '@zero5/permit-api';

import App from '@/containers/App';

import { apiInstance } from './api';

// package's API instance (axios) needs to be compatible with app's one
// TODO: update axios in API packages to match current axios version

// @ts-ignore
initializeAuthApi(process.env.API_GATEWAY_URL!, apiInstance);
// @ts-ignore
initializePaymentApi(process.env.API_GATEWAY_URL!, apiInstance);
// @ts-ignore
initializePermitApi(process.env.API_GATEWAY_URL!, apiInstance);

ReactDOM.render(<App />, document.querySelector('#root'));