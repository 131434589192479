import { useQuery, UseQueryOptions } from 'react-query';

import { listDriverPaymentMethods } from '@zero5/payment-api';

import { PaymentMethod } from '@/api/paymentMethod/models';

import { mapPaymentMethods } from './mappings';
import { paymentMethodsQueryKeys } from './queryKeys';

type Response = Array<PaymentMethod>;
type Key = ReturnType<typeof paymentMethodsQueryKeys.list>;

const usePaymentMethodsQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    paymentMethodsQueryKeys.list(),
    async () => {
      const response = await listDriverPaymentMethods();

      return mapPaymentMethods(response.paymentMethods);
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default usePaymentMethodsQuery;
