import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const LoadingSpinner: React.FC = () => {
  return (
    <Wrapper>
      <CircularProgress />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default LoadingSpinner;
