import store from '@/store';
import { authActions } from '@/store/actions/auth';

import queryClient from './queryClient';

export const clearUserData = async () => {
  store.dispatch(authActions.signIn(null));
  await queryClient.cancelQueries();
  await queryClient.cancelMutations();
  queryClient.clear();
};
