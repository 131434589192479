import { useMutation, UseMutationOptions } from 'react-query';

import { endUserChangePassword } from '@zero5/auth-api';

type Response = {
  status: 'ok';
};
type Params = {
  email: string;
  password: string;
  oldPassword: string;
};

const useChangePasswordMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'changePassword',
    async (params) => {
      const response = await endUserChangePassword(params);

      return response;
    },
    options,
  );
};

export default useChangePasswordMutation;
