import { useQuery, UseQueryOptions } from 'react-query';

import { endUserGetPermitInvite } from '@zero5/permit-api';

import { permitQueryKeys } from './queryKeys';
import { mapPermitEntry } from './mappings';

type Response = ReturnType<typeof mapPermitEntry> | null;
type Params = {
  permitInviteId: number;
};
type Key = ReturnType<typeof permitQueryKeys.entry>;

const usePermitEntryQuery = (
  params: Params,
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    permitQueryKeys.entry(params.permitInviteId),
    async () => {
      const response = await endUserGetPermitInvite(params.permitInviteId);

      return response.permitInvite && mapPermitEntry(response);
    },
    {
      refetchOnWindowFocus: false,
      ...options,
    },
  );
};

export default usePermitEntryQuery;
