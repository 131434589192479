import React from 'react';
import styled from 'styled-components';

interface Props {}

const Notifications: React.FC<Props> = ({}) => {
  return (
    <Wrapper>
      No notifications
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: calc(100% - 22px);
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default Notifications;
