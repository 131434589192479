import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import { Button, Input, Link } from '@zero5/ui';
import schemas from '@zero5/ui/lib/utils/validation/schemas';
import { SignInStatus } from '@zero5/ui-utils';
import vars from '@zero5/ui/lib/styles/vars';

import useSignInMutation from '@/api/auth/useSignInMutation';
import usePasswordChallengeMutation from '@/api/auth/usePasswordChallengeMutation';

import PasswordInfo from '@/components/PasswordInfo';

import { confirmPasswordSchema } from '@/utils/validation/schemas';
import { withUnauthorized } from '@/utils/hocs/withUnauthorized';

const validationSchema = yup.object({
  email: schemas.emailSchema,
  password: yup
    .string()
    .required('Password is required'),
});

const DEFAULT_REDIRECT = '/user/payment';

const getRedirect = (search: string) => {
  return new URLSearchParams(search).get('redirect') || DEFAULT_REDIRECT;
};

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [isChallenge, setIsChallenge] = React.useState(false);

  const signInMutation = useSignInMutation({
    onSuccess: (status) => {
      switch (status) {
        case SignInStatus.SUCCESS:
          history.push(getRedirect(location.search));
          break;
        case SignInStatus.CHALLENGE_REQUIRED:
        case SignInStatus.NEW_PASSWORD_REQUIRED:
          setIsChallenge(true);
          break;
        default:
          throw new Error(status);
      }
    },
  });
  const passwordChallengeMutation = usePasswordChallengeMutation({
    onSuccess: () => {
      history.push(getRedirect(location.search));
    },
  });

  const formikAuthorization = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: async ({ email, password }) => {
      await signInMutation.mutateAsync({ email, password });
    },
  });

  const formikChallenge = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: yup.object().shape(confirmPasswordSchema),
    onSubmit: async ({ newPassword }) => {
      await passwordChallengeMutation.mutateAsync({
        email: formikAuthorization.values.email,
        oldPassword: formikAuthorization.values.password,
        password: newPassword,
      });
    },
  });

  const transformedEmailChangeHandler = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value: string) => {
      formikAuthorization.setFieldValue(e.target.name.toLowerCase(), value.replace(/\t|\s/g, ''));
    }, [formikAuthorization],
  );

  if (isChallenge) {
    return (
      <Page>
        <LoginForm onSubmit={formikChallenge.handleSubmit}>
          <MainHeading align="center">
            Change Password
          </MainHeading>
          <StyledInput
            placeholder="New Password"
            name="newPassword"
            type="password"
            onBlur={formikChallenge.handleBlur}
            onChange={formikChallenge.handleChange}
            value={formikChallenge.values.newPassword}
            error={Boolean(formikChallenge.touched.newPassword && formikChallenge.errors.newPassword)}
            helperText={Boolean(formikChallenge.touched.newPassword) ? formikChallenge.errors.newPassword : undefined}
          />
          <StyledInput
            placeholder="Confirm Password"
            name="confirmPassword"
            type="password"
            onChange={formikChallenge.handleChange}
            value={formikChallenge.values.confirmPassword}
            error={Boolean(formikChallenge.touched.confirmPassword && formikChallenge.errors.confirmPassword)}
            helperText={
              Boolean(formikChallenge.touched.confirmPassword) ? formikChallenge.errors.confirmPassword : undefined
            }
          />
          <PasswordInfo />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            loading={formikChallenge.isSubmitting}
          >
            Change Password
          </Button>
        </LoginForm>
      </Page>
    );
  }

  return (
    <Page>
      <LoginForm onSubmit={formikAuthorization.handleSubmit}>
        <MainHeading align="center">
          Sign In
        </MainHeading>
        <StyledInput
          placeholder="Email"
          name="email"
          transform="lowercase"
          autoComplete="username"
          onChange={transformedEmailChangeHandler}
          value={formikAuthorization.values.email}
          error={Boolean(formikAuthorization.touched.email && formikAuthorization.errors.email)}
          helperText={Boolean(formikAuthorization.touched.email) ? formikAuthorization.errors.email : undefined}
          data-test="signin_email"
        />
        <StyledInput
          placeholder="Password"
          type="password"
          name="password"
          autoComplete="current-password"
          onChange={formikAuthorization.handleChange}
          value={formikAuthorization.values.password}
          error={Boolean(formikAuthorization.touched.password && formikAuthorization.errors.password)}
          helperText={Boolean(formikAuthorization.touched.password) ? formikAuthorization.errors.password : undefined}
          data-test="signin_password"
        />
        <BottomWrapper>
          <Link to="/reset-password">Forgot password?</Link>
        </BottomWrapper>
        <SignInButton
          type="submit"
          fullWidth
          loading={formikAuthorization.isSubmitting}
          data-test="signin_button"
        >
          Sign In
        </SignInButton>
        <Typography>
          By signing in, you acknowledge that you have read and
          understood our
          {' '}
          <StyledLink href="https://zero5.co/policies/privacy-policy">privacy notice</StyledLink>
          .
        </Typography>
      </LoginForm>
      <Typography variant="body1">
        Don’t have an account?
        {' '}
        <StyledLink href="mailto:support@zero5.co">Contact us</StyledLink>
      </Typography>
    </Page>
  );
};

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const StyledLink = styled.a`
  color: ${vars.palette.primary};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }  
`;

const LoginForm = styled.form`
  width: 400px;
  padding: 40px;
  margin-bottom: 10px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 3%);
  background-color: #ffffff;
  border-radius: 4px;
`;

const MainHeading = styled(Typography)`
  margin-bottom: 30px;
  font-size: 28px;
  font-weight: 300;
`;

const StyledInput = styled(Input)`
  margin-bottom: 20px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 20px;
`;

const SignInButton = styled(Button)`
  margin-bottom: 20px;
`;

export default withUnauthorized(Login);
