import React from 'react';
import styled from 'styled-components';
import {
  CardCvcElementComponent,
  CardExpiryElementComponent,
  CardNumberElementComponent,
  CardCvcElementProps,
  CardNumberElementProps,
  CardExpiryElementProps,
} from '@stripe/react-stripe-js';
import { StripeElementStyle } from '@stripe/stripe-js';
import Color from 'color';

import vars from '@zero5/ui/lib/styles/vars';
import InputRoot, { InputRootProps } from '@zero5/ui/lib/InputRoot';

interface CardCvcProps extends CardCvcElementProps {
  component: CardCvcElementComponent;
}

interface CardNumberProps extends CardNumberElementProps {
  component: CardNumberElementComponent;
}

interface CardExpiryProps extends CardExpiryElementProps {
  component: CardExpiryElementComponent;
}

type Props = (CardCvcProps | CardNumberProps | CardExpiryProps) & Omit<InputRootProps, 'children'>;

const style: StripeElementStyle = {
  base: {
    fontSize: '14px',
    lineHeight: '20px',
    fontFamily: 'Roboto,sans-serif',
    '::placeholder': {
      fontWeight: 400,
    },
  },
};

const defaultCardOptions = {
  style,
};

const PaymentInput: React.FC<Props> = ({
  component: Component,
  options,

  className,
  id,
  label,
  helperText,
  error,
  isLoading,
  disabled,
  value,

  ...props
}) => {
  return (
    <InputRoot
      className={className}
      id={id}
      label={label}
      helperText={helperText}
      error={error}
      isLoading={isLoading}
      disabled={disabled}
      value={value}
    >
      <StyledComponent error={error}>
        <Component
          options={{
            ...defaultCardOptions, 
            ...options,
          }}
          {...props as unknown}
        />
      </StyledComponent>
    </InputRoot>
  );
};

type InputWrapperProps = {
  error?: boolean;
  $startAdornment?: boolean;
  $endAdornment?: boolean;
};

const StyledComponent = styled.div<InputWrapperProps>`
  & > * {
    display: block;
    width: 100%;
    height: 40px;
    padding: 9px 17px;
    background-color: ${({ error }) => (error ? '#fef9fa' : 'transparent')};
    border-radius: 0;
    border: 1px solid ${({ error }) => (error ? vars.palette.light_danger : '#e6ecf0')};
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    line-height: 1.4;
    box-sizing: border-box;
    appearance: none;

    &.StripeElement--focus {
      border-color: ${({ error }) => (error ? vars.palette.light_danger : vars.palette.primary)};
      box-shadow: 0 0 0 0.2rem ${({ error }) => (error
    ? `${Color(vars.palette.light_danger).rgb().fade(0.85).string()}`
    : `${Color(vars.palette.primary).rgb().fade(0.8).string()}`)};
      outline: none;
    }
  }
`;

export default PaymentInput;
