import toast from '@zero5/ui/lib/utils/toast';

export const handleError = (error: unknown, customErrorDescription: string = 'Unexpected error!') => {
  if (error instanceof Error) {
    toast('error', error.message);
  } else {
    toast('error', customErrorDescription);
    console.error(error);
  }
};
