import { createReducerFunction, ImmerReducer } from 'immer-reducer';

export interface WorkspaceState {
  currentGarage: unknown;
}

const initialState: WorkspaceState = {
  currentGarage: null,
};

export class WorkspaceReducer extends ImmerReducer<WorkspaceState> {
  setCurrentGarage(garage: unknown) {
    this.draftState.currentGarage = garage;
  }
}

export default createReducerFunction(WorkspaceReducer, initialState);
