import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { updateDriverPaymentMethod } from '@zero5/payment-api';

import { handleError } from '@/utils/handleError';

import { paymentMethodsQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  paymentMethodId: number;
  payload: {
    card: {
      exp_month: number;
      exp_year: number;
    };
    address: {
      city: string;
      line1: string;
      line2?: string;
      postal_code: string;
      state: string;
    };
    cardHolderName: string;
  };
};

const useUpdatePaymentMethodMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'changePaymentMethod',
    async ({ paymentMethodId, payload }) => {
      const response = await updateDriverPaymentMethod({
        paymentId: paymentMethodId,
        card: {
          expirationMonth: payload.card.exp_month,
          expirationYear: payload.card.exp_year,
        },
        address: {
          city: payload.address.city,
          postalCode: payload.address.postal_code,
          line1: payload.address.line1,
          line2: payload.address.line2,
          state: payload.address.state,
        },
        cardHolderName: payload.cardHolderName,
      });

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(paymentMethodsQueryKeys.list());

        options?.onSuccess?.(...params);
      },
      onError: (error) => {
        handleError(error, 'Error while updating payment method.');
      },
    },
  );
};

export default useUpdatePaymentMethodMutation;
