import styled from 'styled-components';

export const PageHeading = styled.h1`
  margin: 0;
  padding: 0;

  margin-bottom: 20px;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

export const TableHeading = styled.h4`
  margin: 0;
  padding: 0;

  margin-bottom: 30px;

  font-weight: 500;
`;