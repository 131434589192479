import React from 'react';
import styled from 'styled-components';

import CardBrandImage from '@/components/payments/CardBrandImage';

interface Props {
  brand: string;
  last4: string;
}

const CardWrapper: React.FC<Props> = ({ brand, last4 }) => {
  return (
    <Wrapper>
      <StyledCardBrandImage brand={brand} />
      **** **** **** {last4}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledCardBrandImage = styled(CardBrandImage)`
  margin-right: 10px;
`;

export default CardWrapper;
