import { useQuery, UseQueryOptions } from 'react-query';

import { getSelfEndUser } from '@zero5/auth-api';

import { mapSelfUser } from './mappings';
import { userQueryKeys } from './queryKeys';

type Response = ReturnType<typeof mapSelfUser>;
type Key = ReturnType<typeof userQueryKeys.self>;

const useSelfUserQuery = (
  options?: UseQueryOptions<Response, unknown, Response, Key>,
) => {
  return useQuery(
    userQueryKeys.self(),
    async () => {
      const response = await getSelfEndUser();

      return mapSelfUser(response);
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      staleTime: Infinity,
      ...options,
    },
  );
};

export default useSelfUserQuery;
