import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button } from '@zero5/ui';

import AddCardImage from '@/assets/images/add_card.svg';

const NoPaymentMethods: React.FC = () => (
  <CenteredWrapper>
    <CardImage
      src={AddCardImage}
      alt="add card"
      width="250"
    />
    <AddCardSection>
      <AddCardTitle>
        Please add a new card
      </AddCardTitle>
      <AddCardContent>
        Currently, you do not have any card on file.
        Before you can purchase any permits, you
        must add a new payment method.
      </AddCardContent>
    </AddCardSection>
    <Link to="/user/payment?addPaymentMethod=1">
      <Button>
        Add new payment method
      </Button>
    </Link>
  </CenteredWrapper>
);

const CenteredWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 375px;
  margin: 50px auto 0 auto;
`;

const CardImage = styled.img`
  margin-bottom: 30px;
`;

const AddCardSection = styled.div`
  margin-bottom: 30px;
`;

const AddCardTitle = styled.h2`
  margin-bottom: 15px;

  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
`;

const AddCardContent = styled.p`
  margin-bottom: 35px;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

export default NoPaymentMethods;
