import { useMutation, UseMutationOptions } from 'react-query';

import { confirmEndUserForgotPassword } from '@zero5/auth-api';

type Response = {
  status: 'ok';
};
type Params = {
  email: string;
  password: string;
  confirmationCode: string;
};

const useConfirmForgotPasswordMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'confirmForgotPassword',
    async (params) => {
      const response = await confirmEndUserForgotPassword(params);

      return response;
    },
    options,
  );
};

export default useConfirmForgotPasswordMutation;
