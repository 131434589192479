import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Button, Modal, Row } from '@zero5/ui';

import usePaymentMethodsQuery from '@/api/paymentMethod/usePaymentMethodsQuery';

export const withPreloadPaymentMethodMessage = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    usePaymentMethodsQuery();

    return <Component {...props} />;
  };

export const withPaymentMethodMessage = <P extends object>(
  Component: React.ComponentType<P>,
): React.FC<P> => (
    props,
  ) => {
    const paymentMethodsQuery = usePaymentMethodsQuery({
      suspense: true,
    });

    if (paymentMethodsQuery.data!.length) {
      return <Component {...props} />;
    } else {
      return (
        <>
          <StyledModal open={true} onClose={() => {}}>
            <Text>
              Payment method needs to be added to proceed. <br></br>
              Please follow the instruction below to add new payment method.
            </Text>
            <Row justifyContent="center">
              <StyledLink to="/user/payment?addPaymentMethod=1">
                <Button
                  variant="contained"
                  color="primary"
                  href="/user/payment?addPaymentMethod=1"
                >
                  Go to “Payment Method” Page
                </Button>
              </StyledLink>
            </Row>
          </StyledModal>
          <Component {...props} />
        </>
      );
    }
  };

const Text = styled.p`
  padding: 0;
  margin-bottom: 30px;
  line-height: 150%;
`;


const StyledLink = styled(Link)`
  text-decoration: none;
`;


const StyledModal = styled(Modal)`
  padding: 30px 50px 30px 50px;
`;
