import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { endUserAcceptPermitInvite } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { permitQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  permitInviteId: number;
  paymentId: number;
  startTime: number;
  isAutoRenewEnabled: boolean;
  vehicle: {
    lpNum: string;
    lpState: string;
    color: string;
    make: string;
    model: string;
  };
};

const useAcceptPermitInviteMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'acceptPermitInvite',
    async (params) => {
      const response = await endUserAcceptPermitInvite(params);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(permitQueryKeys.lists());

        options?.onSuccess?.(...params);
      },
      onError: (error) => {
        handleError(error, 'Error while accepting permit invite.');
      },
    },
  );
};

export default useAcceptPermitInviteMutation;
