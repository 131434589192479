import { AxiosInstance } from 'axios';

import {
  AuthApiProviderInterface,
  SystemUserNewPasswordChallengeArgs,
  SystemUserSignInArgs,
  SystemUserSignInResponse,
  Tokens,
} from '@zero5/ui-utils';
import {
  endUserRefreshToken as refreshTokenApi,
  endUserNewPasswordChallenge as systemUserNewPasswordChallengeApi,
  endUserSignIn as systemUserSignInApi,
} from '@zero5/auth-api';

// Related to ts-ignores below:
// package's API instance (axios) needs to be compatible with app's one
// TODO: update axios in API packages to match current axios version

class AuthApiProvider implements AuthApiProviderInterface {
  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  async refreshToken(refreshToken: string, axiosInstance: AxiosInstance): Promise<Tokens> {
    return (await refreshTokenApi({ refreshToken }, axiosInstance)).tokens;
  }

  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  async systemUserNewPasswordChallenge(
    args: SystemUserNewPasswordChallengeArgs,
    axiosInstance: AxiosInstance,
  ): Promise<Tokens> {
    return (await systemUserNewPasswordChallengeApi(args, axiosInstance)).tokens;
  }

  // @ts-ignore
  // eslint-disable-next-line class-methods-use-this
  async systemUserSignIn(args: SystemUserSignInArgs, axiosInstance: AxiosInstance): Promise<SystemUserSignInResponse> {
    return systemUserSignInApi(args, axiosInstance);
  }
}

const authApiProvider = new AuthApiProvider();
export default authApiProvider;