import {
  GetUserGarageRoleResponse,
} from '@zero5/user-api';
import { Response as SelfUserResponse } from '@zero5/auth-api/lib/src/functions/getSelfEndUser/types';

export const mapUserGarageRole = (response: GetUserGarageRoleResponse) => ({
  isTenantUser: response.isTenantUser,
  isOwner: response.isOwner,
  role: response.role ? {
    label: response.role.label,
    description: response.role.description,
    uiPermissions: response.role.uiPermissions,
  } : null,
});

export const mapSelfUser = (data: SelfUserResponse) => ({
  user: {
    firstName: data.driver.firstName,
    lastName: data.driver.lastName,
    email: data.driver.email,
  },
});