import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';

import useToggle from '@zero5/ui/lib/utils/hooks/useToggle';
import { Button, Input, Modal, Row, Skeleton } from '@zero5/ui';

import useSelfUserQuery from '@/api/user/useSelfUserQuery';
import useChangePasswordMutation from '@/api/auth/useChangePasswordMutation';

import { confirmPasswordSchema } from '@/utils/validation/schemas';
import useModal from '@/utils/hooks/useModal';

interface Props {}

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required('Current Password'),
  ...confirmPasswordSchema,
});

const AccountSettings: React.FC<Props> = () => {
  const [editPassword, closeEditPassword, openEditPassword] = useToggle(false);

  const [
    PasswordUpdatedModal,
    openPasswordUpdatedModal,
    closePasswordUpdatedModal,
  ] = useModal(undefined, StyledPasswordUpdatedModal);

  const selfUserQuery = useSelfUserQuery();

  const changePasswordMutation = useChangePasswordMutation({
    onSuccess: () => {
      openPasswordUpdatedModal();
    },
  });

  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async ({
      oldPassword,
      newPassword,
    }) => {
      if (!selfUserQuery.data?.user.email) {
        throw new Error('User data is not loaded');
      }

      await changePasswordMutation.mutateAsync({
        email: selfUserQuery.data.user.email,
        oldPassword,
        password: newPassword,
      });

      formik.resetForm();
    },
  });

  return (
    <>
      <Wrapper>
        <Field>Name</Field>
        <Value>
          <StringValue>
            {selfUserQuery.isLoading ? (
              <Skeleton width="150px" />
            ) : `${selfUserQuery.data!.user.firstName} ${selfUserQuery.data!.user.lastName}`}
          </StringValue>
        </Value>
        <Field>Email</Field>
        <Value>
          <StringValue>
            {selfUserQuery.isLoading ? (
              <Skeleton width="150px" />
            ) : selfUserQuery.data!.user.email}
          </StringValue>
        </Value>
        <Field>Password</Field>
        <Value>
          {editPassword ? (
            <UpdatePasswordForm onSubmit={formik.handleSubmit}>
              <FieldsGrid>
                <Input
                  label="Enter current password"
                  type="password"
                  name="oldPassword"
                  value={formik.values.oldPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                  helperText={formik.touched.oldPassword ? formik.errors.oldPassword : undefined}
                />
                <Input
                  label="Enter new password"
                  type="password"
                  name="newPassword"
                  value={formik.values.newPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                  helperText={formik.touched.newPassword ? formik.errors.newPassword : undefined}
                />
                <Input
                  label="Re-enter new password"
                  type="password"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                  helperText={formik.touched.confirmPassword ? formik.errors.confirmPassword : undefined}
                />
              </FieldsGrid>
              <Row gap="10px">
                <Button
                  type="button"
                  variant="outlined"
                  onClick={closeEditPassword}
                  disabled={formik.isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  loading={formik.isSubmitting}
                >
                  Save
                </Button>
              </Row>
            </UpdatePasswordForm>
          ) : (
            <Password>
              <StringValue>
                ***************
              </StringValue>
              <ChangePasswordButton onClick={openEditPassword}>Change password</ChangePasswordButton>
            </Password>
          )}
        </Value>
      </Wrapper>
      <PasswordUpdatedModal>
        <>
          <PasswordUpdatedText>
            Password has been successfully updated!
          </PasswordUpdatedText>
          <Row justifyContent="center">
            <Button onClick={closePasswordUpdatedModal}>Confirm</Button>
          </Row>
        </>
      </PasswordUpdatedModal>
    </>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(min-content,1fr);
  grid-column-gap: 30px;
`;

const Field = styled.div`
  padding: 10px 0;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const Value = styled.div`
  padding: 10px 0;
`;

const StringValue = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #A2A2A2;
`;

const Password = styled.div`
  white-space: nowrap;
`;

const ChangePasswordButton = styled.button`
  padding: 0;
  background: transparent;
  border: none;

  margin-left: 20px;

  font-weight: 300;
  font-size: 14px;
  line-height: 16px;
  color: #386344;
  text-decoration: underline;
  cursor: pointer;

  &:focus, &:hover {
    text-decoration: none;
    outline: none;
  }
`;

const UpdatePasswordForm = styled.form`
  min-width: 235px;
`;

const FieldsGrid = styled.div`
  display: grid;
  gap: 20px;

  margin-bottom: 20px;
`;

const StyledPasswordUpdatedModal = styled(Modal)`
  padding: 60px;
`;

const PasswordUpdatedText = styled.div`
  margin-bottom: 35px;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #000000;
  text-align: center;
`;

export default AccountSettings;
