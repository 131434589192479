import { useMutation, UseMutationOptions } from 'react-query';

import { endUserForgotPassword } from '@zero5/auth-api';

type Response = {
  destination: string;
  deliveryMedium: 'SMS' | 'EMAIL';
};
type Params = {
  email: string;
};

const useForgotPasswordMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  return useMutation(
    'forgotPassword',
    async (params) => {
      const response = await endUserForgotPassword(params);

      return response;
    },
    options,
  );
};

export default useForgotPasswordMutation;
