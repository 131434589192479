import {
  GetDriverPermitTableResponseType,
  Permit as PermitResponse,
  PermitPaymentType as PermitPaymentTypeResponse,
  PermitStatus as PermitStatusResponse,
  PermitInviteStatus as PermitInviteStatusResponse,
  PermitTypeData,
  DriverPermitTableEntry,
  VehicleData,
  PermitInviteData,
} from '@zero5/permit-api';

import {
  Permit,
  PermitEntry,
  PermitInvite,
  PermitInviteStatus,
  PermitPaymentType,
  PermitStatus,
  PermitType,
} from './models';

const mapPermitType = (data: PermitTypeData): PermitType => ({
  id: data.id,
  name: data.name,
  price: data.price,
  garageId: data.garageId,
});

const mapPaymentType: Record<PermitPaymentTypeResponse, PermitPaymentType> = {
  [PermitPaymentTypeResponse.INVOICE]: 'INVOICE',
  [PermitPaymentTypeResponse.PREPAID]: 'PREPAID',
};

const mapPermitStatus: Record<PermitStatusResponse, PermitStatus> = {
  [PermitStatusResponse.ACTIVE]: 'ACTIVE',
  [PermitStatusResponse.DEACTIVATED]: 'DEACTIVATED',
  [PermitStatusResponse.EXPIRED]: 'EXPIRED',
  [PermitStatusResponse.PAYMENT_FAILED]: 'PAYMENT_FAILED',
  [PermitStatusResponse.PAYMENT_PROCESSING]: 'PAYMENT_PROCESSING',
};

const mapPermitInviteStatus: Record<PermitInviteStatusResponse, PermitInviteStatus> = {
  [PermitInviteStatusResponse.ACCEPTED]: 'ACCEPTED',
  [PermitInviteStatusResponse.EXPIRED]: 'EXPIRED',
  [PermitInviteStatusResponse.PENDING]: 'PENDING',
};

const mapPermit = (data: PermitResponse): Permit => ({
  permitId: data.permitId,
  type: mapPermitType(data.type),
  paymentId: data.paymentId,
  payerId: data.payerId,
  vehicleId: data.vehicleId,
  driverId: data.driverId,
  garageId: data.garageId,
  paymentType: mapPaymentType[data.paymentType],
  startTime: data.startTime,
  endTime: data.endTime,
  status: mapPermitStatus[data.status],
  lastTransactionId: data.lastTransactionId,
  qr: data.qr,
  isEnabled: data.isEnabled,
  isAutoRenewEnabled: data.isAutoRenewEnabled,
});

const mapVehicle = (data: VehicleData) => ({
  vehicleId: data.vehicleId,
  lpNum: data.lpNum,
  lpState: data.lpState,
  make: data.make,
  model: data.model,
  color: data.color,
});

const mapPermitInvite = (data: PermitInviteData): PermitInvite => ({
  permitInviteId: data.permitInviteId,
  permitType: mapPermitType(data.permitType),
  status: mapPermitInviteStatus[data.status],
});

export const mapPermitEntry = (entry: DriverPermitTableEntry): PermitEntry => ({
  permit: entry.permit && mapPermit(entry.permit),
  vehicle: entry.vehicle && mapVehicle(entry.vehicle),
  permitInvite: mapPermitInvite(entry.permitInvite),
});

export const mapPermitEntries = (data: GetDriverPermitTableResponseType) => ({
  entries: data.entries.map(mapPermitEntry),
  permitsCount: data.entriesCount,
});