import React from 'react';
import {
  Switch, Route, Router,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import { createGlobalStyle } from 'styled-components';
import {
  createTheme,
  CssBaseline,
  StylesProvider,
  ThemeProvider,
} from '@material-ui/core';
import { QueryClientProvider } from 'react-query';
import { Helmet } from 'react-helmet';
import { QueryParamProvider } from 'use-query-params';

import { themeOptions } from '@zero5/ui';
import APIKeyContext from '@zero5/ui/lib/AutocompleteInput/apiKeyContext';

import store from '@/store';

import history from '@/utils/history';
import queryClient from '@/utils/queryClient';

import Account from '@/containers/Account';
import Login from '@/containers/Login';
import PaymentHistory from '@/containers/PaymentHistory';
import Permits from '@/containers/Permits';
import PageNotFound from '@/containers/PageNotFound';
import Payment from '@/containers/Payment';
import Landing from '@/containers/Landing';
import PrivacyPolicy from '@/containers/Privacy/PrivacyPolicy';
import TermsOfUse from '@/containers/Privacy/TermsOfUse';
import ResetPassword from '@/containers/ResetPassword';

import Favicon32 from '@/assets/favicons/favicon-32x32.png';
import Favicon16 from '@/assets/favicons/favicon-16x16.png';
import AppleTouchIcon from '@/assets/favicons/apple-touch-icon.png';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
  }

  html {
    font-size: 14px;
    scroll-behavior: smooth;
  }

  body {
    background-color: #ffffff;
    color: #393F49;
    font-family: Roboto, sans-serif;
  }
`;

const theme = createTheme(themeOptions);

const App: React.FC = () => (
  <Router history={history}>
    <QueryParamProvider ReactRouterRoute={Route}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <APIKeyContext.Provider value={process.env.GOOGLE_API_KEY!}>
              <Helmet>
                <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
                <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
                <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
              </Helmet>
              <ThemeProvider theme={theme}>
                <StylesProvider injectFirst>
                  <CssBaseline />
                  <GlobalStyle />

                  <Switch>
                    <Route path="/" component={Landing} exact />
                    <Route path="/user/account" component={Account} exact />
                    <Route path="/policies/privacy-policy" component={PrivacyPolicy} exact />
                    <Route path="/policies/terms-of-use" component={TermsOfUse} exact />
                    <Route path="/login" component={Login} exact />
                    <Route path="/reset-password" component={ResetPassword} exact />
                    <Route path="/user/payment" component={Payment} exact />
                    <Route path="/user/payment-history" component={PaymentHistory} exact />
                    <Route path="/user/permits" component={Permits} exact />
                    <Route path="*" component={PageNotFound} />
                  </Switch>
                </StylesProvider>
              </ThemeProvider>
          </APIKeyContext.Provider>
        </QueryClientProvider>
      </Provider>
    </QueryParamProvider>
  </Router>
);

export default App;
