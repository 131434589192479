import React from 'react';
import { useQueryParam, BooleanParam, QueryParamConfig } from 'use-query-params';

import { Modal as ModalImport, ModalProps } from '@zero5/ui';

const StringNumberParam: QueryParamConfig<string | number | undefined> = {
  encode: (value) => {
    return value?.toString();
  },
  decode: (value) => {
    if (typeof value !== 'string') return undefined;

    const number = Number(value);

    return isNaN(number) ? value : number;
  },
};

const useModal = <T extends unknown = undefined>(name?: string, Modal: React.FC<ModalProps> = ModalImport) => {
  const [data, setData] = React.useState<T | null>(null);
  const [dataURL, setDataURL] = useQueryParam(name || 'not_set', StringNumberParam);

  const [isOpen, setIsOpen] = React.useState(Boolean(dataURL));
  const [isOpenURL, setIsOpenURL] = useQueryParam(name || 'not_set', BooleanParam);

  type RenderFunction = ((props: { data: T }) => React.ReactElement);
  type NewProps = Omit<ModalProps, 'open' | 'onClose' | 'children'> & {
    children: T extends undefined ? React.ReactElement : RenderFunction;
  };
  type Open = T extends undefined ? () => void : (data: T) => void;

  const openModal = React.useCallback((modalData?: T) => {
    setIsOpen(true);

    if (name && !modalData) {
      setIsOpenURL(true);
    }

    if (modalData) {
      if (name && (typeof modalData === 'string' || typeof modalData === 'number')) {
        setDataURL(modalData);
      } else {
        setData(modalData);
      }
    }
  }, [name, setDataURL, setIsOpenURL]);

  const closeModal = React.useCallback(() => {
    setIsOpen(false);

    if (name) {
      setIsOpenURL(undefined);
    }

    if (name) {
      setDataURL(undefined);
    } else {
      setData(null);
    }
  }, [name, setDataURL, setIsOpenURL]);

  const M: React.FC<NewProps> = ({ children, ...props }) => {
    const Children: React.FC = () => {
      return (children as RenderFunction)({ data: name ? dataURL as T : data! });
    };

    const renderChildren = typeof children === 'function'
      ? <Children />
      : children;

    return (
      <Modal
        open={isOpen || Boolean(isOpenURL)}
        onClose={closeModal}
        children={renderChildren}
        {...props}
      />
    );
  };

  return [M, openModal as Open, closeModal] as const;
};

export default useModal;