import React, { Suspense } from 'react';
import styled, { css } from 'styled-components';

import { Button, Link, Row, SimpleRowsMap, SimpleTable, Skeleton } from '@zero5/ui';
import { formatPrice } from '@zero5/ui/lib/utils/formatters/formatPrice';

import { PaymentReceipt } from '@/api/paymentHistory/models';
import usePaymentReceiptQuery from '@/api/paymentHistory/usePaymentReceiptQuery';

import useDateModule from '@/utils/date/useDateModule';
import { handleError } from '@/utils/handleError';

import PaymentStatus from './PaymentStatus';

interface Props {
  id: string;
  onClose: () => void;
}

const permitType = 'permitType';

const PaymentHistoryLoader: React.FC<Props> = ({ ...props }) => {
  return (
    <Suspense fallback={<StyledSkeleton variant="rect" />}>
      <PaymentHistoryModal {...props} />
    </Suspense>
  );
};

const StyledSkeleton = styled(Skeleton)`
  width: 100vw;
  max-width: 100%;
  min-height: 485px;
  height: 100%;
`;

const PaymentHistoryModal: React.FC<Props> = ({
  onClose,
  id,
}) => {
  const {
    formatFullMonthDateYear,
  } = useDateModule();

  const paymentReceiptQuery = usePaymentReceiptQuery({
    transactionId: id,
  }, {
    suspense: true,
    onError: (error) => {
      onClose();
      handleError(error);
    },
  });

  const paymentInfoRowsMap: SimpleRowsMap<PaymentReceipt> = [
    {
      title: 'Date',
      data: ({ timestamp }) => formatFullMonthDateYear(timestamp),
    },
    {
      title: 'Payment Method',
      data: ({ paymentMethod }) => paymentMethod,
    },
    {
      title: 'Payment Status',
      data: ({ paymentStatus }) => <PaymentStatus status={paymentStatus} />,
    },
    {
      title: 'Receipt Number',
      data: ({ receiptNumber }) => receiptNumber,
    },
  ];

  const garageName = React.useMemo(() => {
    return paymentReceiptQuery.data!.summary[0].location;
  }, [paymentReceiptQuery.data]);

  return (
    <Wrapper>
      <Title>{garageName}_RECEIPT</Title>
      <PaymentInfoSimpleTable
        rowsMap={paymentInfoRowsMap}
        item={paymentReceiptQuery.data!}
      />
      <Title as="h3">Summary</Title>
      <TableContainer>
        <SummaryTable>
          <thead>
            <tr>
              <SummaryTH>Item</SummaryTH>
              <SummaryTH>Location</SummaryTH>
              <SummaryTH>Price</SummaryTH>
            </tr>
          </thead>
          <tbody>
            {paymentReceiptQuery.data!.summary.map(({ item, location, price }) => (
              <SummaryTableContentTR>
                <SummaryTD>{item}</SummaryTD>
                <SummaryTD>{location}</SummaryTD>
                <SummaryTD>{formatPrice(price)}</SummaryTD>
              </SummaryTableContentTR>
            ))}
          </tbody>
        </SummaryTable>
      </TableContainer>
      <ModalText>
        By purchasing Monthly Parking Permit, you authorize
        {' '}
        {garageName}
        {' '}
        to automatically charge you each month until you cancel.
        If the price changes, we will notify you beforehand.
        You can check your renewal date or cancel anytime via your Account page.
        No partial refunds.
        {' '}
        {garageName}
        {' '}
        {permitType}
        {' '}
        terms and conditions apply.

        <StyledLink to="/terms">Terms and conditions</StyledLink>
      </ModalText>
      <ButtonRow justifyContent="flex-end">
        <Button onClick={onClose}>Close</Button>
      </ButtonRow>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 0;
  margin-bottom: 20px;

  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
`;

const TableContainer = styled.div`
  padding: 10px;
  margin-bottom: 30px;
  background-color: #F0F0F0;
`;

const summaryTableCellProps = css`
  padding: 10px;

  text-align: center;

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
`;

const SummaryTH = styled.th`
  ${summaryTableCellProps}

  color: #A2A2A2;
  font-weight: 400;
`;

const SummaryTD = styled.td`
  ${summaryTableCellProps}
`;

const SummaryTableContentTR = styled.tr`
  &:last-child {
    & ${SummaryTD} {
      padding-bottom: 20px;
    }
  }
`;

const SummaryTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`;

const PaymentInfoSimpleTable = styled(SimpleTable)`
  margin-bottom: 30px;

  font-size: 14px;
  line-height: 16px;

  th, td {
    padding: 5px 0;
  }

  th {
    color: #A2A2A2;
    text-align: left;
  }

  td {
    text-align: right;
  }
` as typeof SimpleTable;

const ModalText = styled.p`
  margin: 0;
  margin-bottom: 20px;

  font-size: 12px;
  font-weight: 300;
  color: #BCBEC0;
`;

const StyledLink = styled(Link)`
  display: block;
  margin-top: 10px;
  font-size: inherit;
  line-height: inherit;
` as typeof Link;

const ButtonRow = styled(Row)`
  margin-top: auto;
`;

export default PaymentHistoryLoader;