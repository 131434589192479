import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { SignInStatus } from '@zero5/ui-utils';

import { authActions } from '@/store/actions/auth';

import { handleError } from '@/utils/handleError';

import user from '../user';

type Response = SignInStatus;
type Params = {
  email: string;
  password: string;
};

const useSignInMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const dispatch = useDispatch();

  return useMutation(
    'signIn',
    async ({ email, password }) => {
      const status = await user.signIn({ email, password: password.trim() });

      dispatch(authActions.signIn(status));

      return status;
    },
    {
      ...options,
      onError: (error) => {
        handleError(error, 'Failed to sign in!');
      },
    },
  );
};

export default useSignInMutation;
