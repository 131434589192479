import { PaymentHistoryItem } from '@zero5/payment-api/lib/src/types/PaymentHistoryReport';
import { Response as PaymentHistoryReportsResponse } from
  '@zero5/payment-api/lib/src/functions/getDriverPaymentHistory/types';
import { PostReceipt } from '@zero5/payment-api/lib/src/types/PostReceipt';

import { PaymentHistory, PaymentReceipt } from './models';

export const mapPaymentHistory = (data: PaymentHistoryItem): PaymentHistory => ({
  date: data.timestamp,
  garageName: data.garageName,
  item: data.item,
  paymentStatus: data.paymentStatus,
  receiptNumber: data.receiptNumber,
  amount: data.amount,
});

const mapPaymentHistories = (data: Array<PaymentHistoryItem>) =>
  data.map(mapPaymentHistory);

export const mapPaymetnHistoryReport = (data: PaymentHistoryReportsResponse['paymentHistory']) => ({
  pageCount: data.pageCount,
  paymentHistories: mapPaymentHistories(data.paymentHistoryItems),
  failedTransactions: mapPaymentHistories(data.failedTransactions),
});

export const mapPaymentReceipt = (data: PostReceipt): PaymentReceipt => ({
  timestamp: data.timestamp,
  paymentMethod: data.paymentMethod,
  paymentStatus: data.paymentStatus,
  receiptNumber: data.receiptNumber,
  summary: data.summary.map((summary) => ({
    item: summary.item,
    location: summary.location,
    price: summary.price,
  })),
  timezone: data.timezone,
});
