import React from 'react';
import styled from 'styled-components';

import { ModalTitle } from '@zero5/ui';

import PenIcon from '@/assets/icons/pen.inline.svg';

interface Props {
  onClick: () => void;
  children: string;
}

const ModalTitleButton: React.FC<Props> = ({ onClick, children }) => {
  return (
    <StyledModalTitleButton
      as="button"
      onClick={onClick}
    >
      {children}
      <StyledPenIcon />
    </StyledModalTitleButton>
  );
};

const StyledModalTitleButton = styled(ModalTitle)`
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
`;

const StyledPenIcon = styled(PenIcon)`
  margin-left: 15px;
`;

export default ModalTitleButton;