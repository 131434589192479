import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { endUserDeletePermitInvite } from '@zero5/permit-api';

import { handleError } from '@/utils/handleError';

import { permitQueryKeys } from './queryKeys';

type Response = unknown;
type Params = {
  permitInviteId: number;
};

const useDeletePermitMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'deletePermitInvite',
    async (params) => {
      const response = await endUserDeletePermitInvite(params.permitInviteId);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(permitQueryKeys.lists());

        options?.onSuccess?.(...params);
      },
      onError: (error) => {
        handleError(error, 'Error while accepting permit invite.');
      },
    },
  );
};

export default useDeletePermitMutation;
