import * as yup from 'yup';

import schemas from '@zero5/ui/lib/utils/validation/schemas';

export const confirmPasswordSchema = {
  newPassword: schemas.password.label('New Password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .required('Confirm Password is required'),
};
