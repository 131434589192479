import { useMutation, UseMutationOptions } from 'react-query';
import { useDispatch } from 'react-redux';

import { SignInStatus } from '@zero5/ui-utils';

import { authActions } from '@/store/actions/auth';

import { handleError } from '@/utils/handleError';

import user from '../user';

type Response = unknown;
type Params = {
  email: string;
  oldPassword: string;
  password: string;
};

const usePasswordChallengeMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const dispatch = useDispatch();

  return useMutation(
    'passwordChallenge',
    async ({ email, oldPassword, password }) => {
      await user.changePassword({
        email,
        oldPassword: oldPassword.trim(),
        password: password.trim(),
      });

      dispatch(authActions.signIn(SignInStatus.SUCCESS));
    },
    {
      ...options,
      onError: (error) => {
        handleError(error, 'Failed to change password!');
      },
    },
  );
};

export default usePasswordChallengeMutation;
