import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';

import { deleteDriverPaymentMethod } from '@zero5/payment-api';

import { handleError } from '@/utils/handleError';

import { paymentMethodsQueryKeys } from './queryKeys';

type Params = {
  paymentMethodId: number;
};
type Response = {
  status: 'ok',
};

const useDeletePaymentMethodMutation = (
  options?: UseMutationOptions<Response, Error, Params, string>,
) => {
  const queryClient = useQueryClient();

  return useMutation(
    'deletePaymentMethod',
    async ({ paymentMethodId }) => {
      const response = await deleteDriverPaymentMethod(paymentMethodId);

      return response;
    },
    {
      ...options,
      onSuccess: (...params) => {
        queryClient.invalidateQueries(paymentMethodsQueryKeys.list());

        options?.onSuccess?.(...params);
      },
      onError: (error) => {
        handleError(error, 'Error while deleting payment method.');
      },
    },
  );
};

export default useDeletePaymentMethodMutation;
