import { Response as PaymentMethodResponse } from '@zero5/payment-api/lib/src/functions/listDriverPaymentMethods/types';

import { ArrayElement } from '@/utils/types';

import { PaymentMethod } from './models';

export const mapPaymentMethod = (data: ArrayElement<PaymentMethodResponse['paymentMethods']>): PaymentMethod => ({
  id: data.id,
  created: data.created,
  isDefault: data.isDefault,
  card: data.card,
  name: data.name,
  address: data.address,
});

export const mapPaymentMethods = (data: PaymentMethodResponse['paymentMethods']) =>
  data.map(mapPaymentMethod);
